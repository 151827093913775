import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../services/user.service';

@Component({
  selector: 'rap-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss']
})
export class PaymentSuccessComponent {
  sessionId: string;
  message: string;
  isLoading = false;
  title: string;
  isPaymentSuccess: boolean;
  constructor(private route: ActivatedRoute, private userService: UserService, private router: Router) { }

  ngOnInit(): void {
    // Extract the session_id from the query parameters
    this.route.queryParams.subscribe(params => {
      this.sessionId = params['session_id'];
      console.log('Checkout Session ID:', this.sessionId);

      this.isLoading = true;
      this.userService.stripePaymentSuccess(this.sessionId).subscribe((result) => {
        console.log(result)
        this.isLoading = false;
        if (result.subscription.status == "active") {
          this.isPaymentSuccess = true;
        } else {
          this.isPaymentSuccess = false;
        }
        this.message = result.message;
      });
    });
  }

  onContinue() {
    if (this.isPaymentSuccess) {
      this.router.navigate(['/dashboard/explore']).then(() => {
        history.replaceState(null, '', '/dashboard/explore');
      });
    } else {
      this.router.navigate(['/signin']);
    }

  }
}
