import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environments';

@Injectable({
  providedIn: 'root'
})
export class CardsService {
  private readonly BASE_URL = 'baseUrl';
  private url: string;


  constructor(private httpClient: HttpClient) {
    this.url = environment.baseUrl;
  }

  getMyCards() {
    console.log(this.url + `cards/my_cards`)
    return this.httpClient.get(
      this.url + `cards/my_cards`,
    ).pipe(map((response: any) => response));

  }
}
