<div class="label-container">
    <div>
        <label class="clickable-label" routerLink="/dashboard/explore"> Rap Almanac </label>
        <img src="../assets/images/filled.svg" />
    </div>
    <div>
        <label class="non-clickable-label">App</label>
        <img src="../assets/images/filled.svg" />
    </div>
    <label (click)="onCards()" class="clickable-label">Cards</label>
</div>

<div class="analytics-section">
    <rap-spinner class="loader-overlay" *ngIf="isLoading"></rap-spinner>
    <div class="section-title"> Cards </div>

    <div class="grid-container">
        <div class="grid-item" *ngFor="let item of data">
            <img src="assets/images/favicon.png" class="logo">
            <h3>Search word : {{ item.searchWord }}</h3>
            <div *ngIf="item.chartType === 'Bar chart'">
                <canvasjs-chart [options]="getChartOptions(item)"
                    [styles]="{ width: '100%', height:'300px'}"></canvasjs-chart>
            </div>
            <div *ngIf="item.chartType === 'Timeline'">
                <canvasjs-chart [options]="getChartOptionsTimeLine(item)"
                    [styles]="{ width: '80%', height:'300px'}"></canvasjs-chart>
            </div>
            <!-- <div *ngIf="item.chartType === 'Nearest Neighbour'">
                <canvasjs-chart [options]="getChartOptions3D(item)"
                    [styles]="{ width: '80%', height:'300px'}"></canvasjs-chart>
            </div> -->
            <div class="button-container">
                <button class="btn">View</button>
                <button class="btn">Copy Link</button>
                <button class="btn">Edit</button>
                <button class="btn">Delete</button>
            </div>
        </div>
    </div>
</div>