import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppService } from './app.service';
import { PasswordHelpComponent } from './password-help/password-help.component';
import { OnboardingModule } from './onboarding/onboarding.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { StoreRouterConnectingModule, routerReducer } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'src/environments/environments';
import { AppStoreModule } from './store/app-store.module';
import { HttpConfigInterceptor } from 'src/interceptors/http-config.interceptors';
import { SharedModule } from './shared/shared.module';
import { BaseTemplateModule } from './base-template/base-template.module';
import { SigninPresenter } from './signin/signin.presenter';
import { SigninComponent } from './signin/signin.component';
import { CreateAccountPresenter } from './create-account/create-account.presenter';
import { CreateAccountComponent } from './create-account/create-account.component';
import { PasswordResetSuccessModalComponent } from './password-help/password-reset-success-modal/password-reset-success-modal.component';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VerifyAccountComponent } from './create-account/verify-account/verify-account.component';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthGuard } from './guards/auth.guard';
import { Ng5SliderModule } from 'ng5-slider';
import { CanvasJSAngularChartsModule } from '@canvasjs/angular-charts';
import { UsersComponent } from './users/users.component';
import { AuthTokenInterceptor } from 'src/interceptors/auth-token.interceptors';
import { AddEditUserComponent } from './users/add-edit-user/add-edit-user.component';
import { AddEditSongComponent } from './songs/add-edit-song/add-edit-song.component';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';
import { UserProfileModule } from './user-profile/user-profile.module';


@NgModule({
  declarations: [
    AppComponent,
    SigninComponent,
    CreateAccountComponent,
    PasswordHelpComponent,
    PasswordResetSuccessModalComponent,
    VerifyAccountComponent,
    PaymentSuccessComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    OnboardingModule,
    UserProfileModule,
    ReactiveFormsModule,
    BaseTemplateModule,
    StoreModule.forRoot(routerReducer, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    AppStoreModule,
    SharedModule,
    ToastrModule.forRoot({
      closeButton: true,
      progressBar: true,
    }),
    NgbPaginationModule,
    Ng5SliderModule,
    CanvasJSAngularChartsModule,
    UserProfileModule,

  ],
  providers: [
    AppService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthTokenInterceptor,
      multi: true,
    },
    SigninPresenter,
    CreateAccountPresenter,
    AuthGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
