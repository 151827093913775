<div class="analytics-section">
  <div class="section-title">Students</div>

  <div class="rosters-table" *ngIf="students?.length > 0">
    <table class="table rounded-4 overflow-hidden">
      <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let roster of paginatedRosters">
          <td scope="row">{{ roster?.name }}</td>
          <td>
            <button
              type="button"
              class="btn btn-light manage-student-btn"
              (click)="manageStudent(roster)"
            >
              Manage students
            </button>
            <button
              type="button"
              class="btn btn-dark delete-roster-btn"
              (click)="deleteRoster(roster.id)"
            >
              Delete
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="pagination">
      <rap-pagination
        [collectionSize]="rosters?.length"
        [page]="page"
        [pageSize]="pageSize"
        (onPageChange)="onPageChange($event)"
      ></rap-pagination>
    </div>
  </div>

  <div class="card" *ngIf="!students || students?.length === 0">
    <div class="card-body">
      <div class="no-rosters-found">No students found</div>
    </div>
  </div>

  <button
    type="button"
    class="btn btn-dark add-role-btn"
    (click)="addStudent()"
  >
    Add new
  </button>
</div>
