import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserData } from 'src/app/models/user-data.model';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { DeleteModalComponent } from 'src/app/shared/component/delete-modal/delete-modal.component';
import { ProfilePhotoModalComponent } from '../profile-photo-modal/profile-photo-modal.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'rap-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {
  @ViewChild('fileInput') fileInput: ElementRef;
  avatar: string | null;
  firstName: string;
  isLoading: boolean = false;
  user: UserData;
  isEditProfile = false;
  editForm: FormGroup;
  isEdit = true;
  constructor(private authService: AuthService, private router: Router, private userService: UserService, private modalService: NgbModal, private fb: FormBuilder) {
    console.log(this.authService.getUserEmail());
    this.editForm = this.fb.group({
      firstname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      username: ['', [Validators.required]],
      // dob: ['', [Validators.required]],
      role: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {
    this.firstName = this.authService.getUserName();
    this.avatar = this.authService.getUserAvatar();
    console.log(this.firstName);
    console.log(this.avatar);
    this.getProfile();
  }

  getProfile() {
    this.isLoading = true;
    this.userService.getProfileDetails(this.authService.getUserId()).subscribe((result) => {
      console.log(result)
      this.isLoading = false;
      this.user = result;
      if (this.user != null) {
        this.authService.storeUserName(this.user.firstName);
        this.editForm = this.fb.group({
          firstname: [this.user.firstName ?? '', [Validators.required]],
          lastname: [this.user.lastName ?? '', [Validators.required]],
          email: [this.user.email ?? '', [Validators.required, Validators.email]],
          username: [this.user.userName ?? '', [Validators.required]],
          // dob: ['', [Validators.required]],
          role: [this.user.role.name ?? '', [Validators.required]]
        });
      }
    });
  }

  onEdit() {
    const modalRef = this.modalService.open(ProfilePhotoModalComponent);
    modalRef.result
      .then((data) => {
        this.isLoading = true;
        data.forEach((value, key) => {
          console.log(key, value);
        });

        this.isLoading = false;
        this.userService.postAvatar(this.authService.getUserId(), data).subscribe((result) => {
          console.log(result)

          this.getProfile();
        });


      })
      .catch((error) => {
        this.isLoading = false;
      });
  }

  onEditProfile() {
    this.isEditProfile = true;
    this.isLoading = false;
  }

  onBack() {
    this.isEditProfile = false;
    this.isLoading = false;
  }

  onEditSubmit() {
    if (this.editForm.valid) {
      console.log('Form Data: ', this.editForm.value);
      var data: any = {
        "id": this.authService.getUserId(),
        "data": {
          "firstName": this.editForm.value.firstname,
          "lastName": this.editForm.value.lastname,
          "userName": this.editForm.value.username,
          "email": this.editForm.value.email
        }
      };
      this.isLoading = true;
      this.userService.putProfileDetails(this.authService.getUserId(), data).subscribe((result) => {
        console.log(result)
        this.isEdit = true;
        this.getProfile();
      });
    } else {
      console.log('Form is not valid');
    }
  }
}
