<div class="sidenav" [ngClass]="collapsed ? 'sidenav-collapsed' : ''">
  <div class="logo-container">
    <!-- <button class="logo" (click)="toggleCollapse()">R</button> -->
    <img src="assets/images/favicon.png" (click)="toggleCollapse()" class="logo">
    <div class="logo-text" *ngIf="!collapsed">Rap Alamanac</div>
    <!-- <button class="btn-close" *ngIf="!collapsed" (click)="closeSidenav()">
      <i class="bi bi-x"></i>
    </button> -->
  </div>
  <!--  <ul class="sidenav-nav">-->
  <!--    <li class="sidenav-item" *ngFor="let data of navData">-->
  <!--      <a-->
  <!--        class="sidenav-link"-->
  <!--        [class.collapsed]="collapsed"-->
  <!--        [routerLink]="[data.routeLink]"-->
  <!--        routerLinkActive="active"-->
  <!--        [routerLinkActiveOptions]="{ exact: true }"-->
  <!--      >-->
  <!--        <i class="sidenav-link-icon" [class]="data.icon"></i>-->
  <!--        <span class="sidenav-link-text" *ngIf="!collapsed">{{-->
  <!--          data.label-->
  <!--        }}</span>-->
  <!--      </a>-->
  <!--    </li>-->
  <!--  </ul>-->

  <ul class="sidenav-nav">
    <li class="sidenav-item" *ngFor="let data of navData" [hidden]="!data.enabled">
      <a class="sidenav-link" (click)="handleClick(data)" *ngIf="data.items && data.items.length > 0"
        routerLinkActive="active">
        <!-- <i class="sidenav-link-icon" [class]="data.icon"></i> -->
        <img [src]="data.icon" class="sidenav-link-icon">
        <span class="sidenav-link-text" *ngIf="!collapsed">{{
          data.label
          }}</span>
        <i *ngIf="data.items && !collapsed" class="menu-collapse-icon" [ngClass]="
            !data.expanded ? 'bi bi-chevron-right' : 'bi bi-chevron-down'
          "></i>
      </a>

      <a class="sidenav-link" *ngIf="(!data.items && data.externalLink === null)" [routerLink]="[data.routeLink]"
        routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <!-- <i class="sidenav-link-icon" [class]="data.icon"></i> -->
        <img [src]="data.icon" class="sidenav-link-icon">
        <span class="sidenav-link-text" *ngIf="!collapsed">{{
          data.label
          }}</span>
      </a>

      <a class="sidenav-link" *ngIf="(!data.items && data.externalLink === false)" [routerLink]="[data.routeLink]"
        routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" target="_blank">
        <!-- <i class="sidenav-link-icon" [class]="data.icon"></i> -->
        <img [src]="data.icon" class="sidenav-link-icon">
        <span class="sidenav-link-text" *ngIf="!collapsed">{{
          data.label
          }}</span>
      </a>

      <a class="sidenav-link" *ngIf="(!data.items && data.externalLink === true)" [attr.href]="[data.routeLink]"
        routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" target="_blank">
        <!-- <i class="sidenav-link-icon" [class]="data.icon"></i> -->
        <img [src]="data.icon" class="sidenav-link-icon">
        <span class="sidenav-link-text" *ngIf="!collapsed">{{
          data.label
          }}</span>
      </a>

      <div *ngIf="!collapsed && data.items && data.items.length > 0">
        <rap-sublevel-menu [data]="data" [collapsed]="!collapsed" [multiple]="multiple" [expanded]="data.expanded">
        </rap-sublevel-menu>
      </div>
    </li>

  </ul>
</div>

<!--      <rap-sublevel-menu-->
<!--        [data]="data"-->
<!--        [collapsed]="!collapsed"-->
<!--      ></rap-sublevel-menu>-->