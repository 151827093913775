import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Student } from '../models/students/student.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { DeleteModalComponent } from '../shared/component/delete-modal/delete-modal.component';

@Component({
  selector: 'rap-students-ui',
  templateUrl: './students.component.html',
  styleUrls: ['./students.component.scss'],
})
export class StudentsComponent {
  @Input() students: Student[];
  @Output() deleteStudentEmitter = new EventEmitter<string>();
  @Output() editStudentEmitter = new EventEmitter<Student>();
  page = 1;
  pageSize = 10;
  @Input() rosterId: string;

  constructor(
    private router: Router,
    private modalService: NgbModal,
  ) {}

  get paginatedStudents(): Student[] {
    const start = (this.page - 1) * this.pageSize;
    const end = start + this.pageSize;
    return this.students.slice(start, end);
  }

  addStudent() {
    this.router.navigate(['/dashboard/rosters/' + this.rosterId + '/add']);
  }

  onPageChange(page: number) {
    this.page = page;
  }

  deleteStudent(functionId: string) {
    const modalRef = this.modalService.open(DeleteModalComponent);
    modalRef.componentInstance.modalTitle = 'Confirm delete';
    modalRef.componentInstance.modalBodyText =
      'Are you sure you want to delete thie item ?';
    modalRef.componentInstance.itemId = functionId;
    modalRef.result
      .then((id) => {
        this.deleteStudentEmitter.emit(id);
      })
      .catch((error) => console.log(error));
  }

  editStudent(student: Student) {
    this.editStudentEmitter.emit(student);
  }
}
