import { Component } from '@angular/core';
import { UserService } from '../services/user.service';
import { AuthService } from '../services/auth.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environments';
import { ExploreFacade } from './store/explopre.facade';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'rap-explore',
  templateUrl: './explore.container.component.html',
  styleUrls: ['./explore.container.component.scss'],
})
export class ExploreContainerComponent {
  searchResults$ = this.exploreFacade.searchResults$;
  timeFrame$ = this.exploreFacade.timeframes$;
  searchTerm$ = this.exploreFacade.searchTerm$;
  constructor(private exploreFacade: ExploreFacade, private router: ActivatedRoute) { }

  ngOnInit() {
    console.log(this.router.snapshot.params['queryParams']);
    this.router.queryParams.subscribe(params => {
      console.log(params)
      console.log(params['queryParams'])
      // this.isExternal = params['isExternal'] === 'true';  // queryParams are usually strings, so convert to boolean
    });
    this.exploreFacade.search('');
  }

  search(search: string) {
    console.log(search)
    this.exploreFacade.search(search);
  }

  searchWithLanguage(language: string) {
    this.exploreFacade.searchWithLanguage(language);
  }

  searchWithTimeframe(dateRange: any) {
    this.exploreFacade.searchWithTimeframe(
      dateRange.startDate,
      dateRange.endDate,
    );
  }

  searchWithSelectedMoods(selectedMoods: any[]) {
    this.exploreFacade.searchWithSelectedMoods(selectedMoods);
  }

  searchWithCountry(location: string) {
    this.exploreFacade.searchWithLocation(location);
  }

  searchCity(city: string) {
    this.exploreFacade.searchWithCity(city);
  }



  searchWithKeyword(event: { included: string[], excluded: string[] }) {
    console.log('Included Keywords:', event.included);
    console.log('Excluded Keywords:', event.excluded);
    this.exploreFacade.searchWithKeywords(event.included, event.excluded);
  }
}
