import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  map,
  mergeMap,
  of,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { StudentsService } from '../students.service';
import {
  createNewStudent,
  createNewStudentSuccess,
  getStudents,
  getStudentsSuccess,
  setErrorMessage,
} from './students.action';
import { StudentsFacade } from './students.facade';

@Injectable()
export class StudentsEffects {
  getStudents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getStudents),
      switchMap((action) =>
        this.stduentsService.getStudents(action.rosterId).pipe(
          map((data: any) => {
            return getStudentsSuccess({ students: data.rows });
          }),
          catchError((error: HttpErrorResponse) => {
            return of();
          }),
        ),
      ),
    ),
  );

  createNewStudent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createNewStudent),
      withLatestFrom(this.stduentsFacade.rosterId$),
      switchMap(([action, rosterId]) =>
        this.stduentsService.createNewStudent(rosterId, action.newStudent).pipe(
          map((data: any) => {
            return createNewStudentSuccess();
          }),
          catchError((error: HttpErrorResponse) => {
            let msg = '';
            if (error.status == 500) {
              msg = 'Something went wrong, please try again later!';
            }
            return of(setErrorMessage({ errorMsg: msg || error.message }));
          }),
        ),
      ),
    ),
  );

  createNewStudentSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createNewStudentSuccess),

        tap(() => {
          this.toastr.success('Student created successfully', '', {
            positionClass: 'toast-top-center',
            closeButton: false,
          });
          this.router.navigate(['/dashboard/rosters']);
        }),
      ),
    { dispatch: false },
  );
  constructor(
    private actions$: Actions,
    private stduentsService: StudentsService,
    private stduentsFacade: StudentsFacade,
    private toastr: ToastrService,
    private router: Router,
  ) {}
}
