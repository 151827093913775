import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environments';
import { CreateRosterRequest } from '../models/rosters/create-roster-request.model';
import { UpdateRosterRequest } from '../models/rosters/update-roster-request.model';

@Injectable({
  providedIn: 'root',
})
export class RostersService {
  private readonly BASE_URL = 'baseUrl';
  private url: string;

  constructor(private httpClient: HttpClient) {
    this.url = environment.baseUrl;
  }

  getRosters() {
    return this.httpClient.get<any>(this.url + `v2/rosters`);
  }

  getRosterById(id: string) {
    return this.httpClient.get<any>(this.url + `v2/rosters/`+ id);
  }

  deleteRoster(rosterId: string) {
    return this.httpClient.delete<any>(this.url + `v2/rosters/` + rosterId);
  }

  createNewRoster(newRoster: CreateRosterRequest) {
    return this.httpClient.post(this.url + `v2/rosters`, newRoster, {
      responseType: 'text',
    });
  }

  updateRoster(updatedRoster: UpdateRosterRequest) {
    return this.httpClient.put(
      this.url + `v2/rosters/` + updatedRoster.id,
      updatedRoster,
      {
        responseType: 'text',
      },
    );
  }
}
