import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RostersSelectors } from './rosters.selectors';
import { RostersState } from './rosters.state';
import {
  clearErrorMessage,
  createNewRoster,
  deleteRoster,
  getRosterById,
  getRosters,
  resetEditableRoster,
  updateRoster,
} from './rosters.action';
import { UpdateRosterRequest } from 'src/app/models/rosters/update-roster-request.model';
import { CreateRosterRequest } from 'src/app/models/rosters/create-roster-request.model';
import { Roster } from 'src/app/models/rosters/rosters.model';

@Injectable({
  providedIn: 'root',
})
export class RostersFacade {
  roles$ = this.rostersSelectors.rosters$;
  editableRoster$ = this.rostersSelectors.editableRoster$;
  errorMessage$ = this.rostersSelectors.errorMessage$;
  constructor(
    private store: Store<RostersState>,
    private rostersSelectors: RostersSelectors,
  ) {}

  getRosters() {
    this.store.dispatch(getRosters());
  }

  getRosterById(rosterId: string) {
    this.store.dispatch(getRosterById({ rosterId }));
  }

  deleteRoster(rosterId: string) {
    this.store.dispatch(deleteRoster({ rosterId }));
  }

  createNewRoster(newRoster: CreateRosterRequest) {
    this.store.dispatch(createNewRoster({ newRoster }));
  }

  updateRoster(updatedRoster: UpdateRosterRequest) {
    this.store.dispatch(updateRoster({ updatedRoster }));
  }

  resetEditableRoster() {
    this.store.dispatch(resetEditableRoster());
  }

  clearErrorMessage() {
    this.store.dispatch(clearErrorMessage());
  }
}
