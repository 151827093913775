import { Injectable } from '@angular/core';
import { Store, createFeatureSelector, createSelector } from '@ngrx/store';
import { ExploreState } from './explore.state';

const getExploreState = createFeatureSelector<ExploreState>('explore');

const getSearchResults = createSelector(
  getExploreState,
  (state: ExploreState) => state.searchResults,
);

const getSearchTerm = createSelector(
  getExploreState,
  (state: ExploreState) => state.searchTerm,
);

const getTimeframes = createSelector(
  getExploreState,
  (state: ExploreState) => state.selectedTimeframe,
);

const getLanguages = createSelector(
  getExploreState,
  (state: ExploreState) => state.selectedLanguage,
);

const getMoods = createSelector(
  getExploreState,
  (state: ExploreState) => state.selectedMoods,
);

const getLocation = createSelector(
  getExploreState,
  (state: ExploreState) => state.selectedLocation,
);

const getInclude = createSelector(
  getExploreState,
  (state: ExploreState) => state.selectedInclude,
);

const getExclude = createSelector(
  getExploreState,
  (state: ExploreState) => state.selectedExclude,
);

@Injectable()
export class ExploreSelectors {
  searchResults$ = this.store.select(getSearchResults);
  searchTerm$ = this.store.select(getSearchTerm);
  timeframes$ = this.store.select(getTimeframes);
  languages$ = this.store.select(getLanguages);
  moods$ = this.store.select(getMoods);
  location$ = this.store.select(getLocation);
  include$ = this.store.select(getInclude);
  exclude$ = this.store.select(getExclude);
  constructor(private store: Store<ExploreState>) { }
}
