import { Component } from '@angular/core';
import { CardsService } from '../cards/cards.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'rap-card-view',
  templateUrl: './card-view.component.html',
  styleUrls: ['./card-view.component.scss']
})
export class CardViewComponent {
  card: any;
  id: string;
  isLoading = true;
  constructor(private cardService: CardsService, private route: ActivatedRoute
  ) {

  }

  ngOnInit(): void {
    this.isLoading = true;
    window.addEventListener('message', (event) => {
      console.log(event.data.id)
      if (event.data) {
        this.card = event.data
        this.isLoading = false
      }
    });

    this.id = this.route.snapshot.paramMap.get('id');
    console.log(this.id)
    this.cardService.getCard(this.id).subscribe((result) => {
      console.log(result)
      this.card = result;
      this.isLoading = false

    });
  }

  getChartOptions(chartData: any) {
    console.log(chartData.chartType)
    if (!chartData.data || !chartData.data[0] || chartData.data[0].data == null) {
      console.log('Invalid data structure:', chartData);
      return {}; // Return an empty object or handle error
    }

    // return {};

    return {
      title: {
        text: ""
      },
      axisY: {
        title: 'Word Count',
        gridThickness: 1,
        tickLength: 10,
      },
      axisX: {
        title: 'Artist'
      },
      data: [{
        type: 'column',
        maxBarThickness: 30,
        dataPoints: chartData.data[0].data.map((item: any) => ({
          label: item.artist,
          y: item.count
        }))
      }]
    };
  }

  getChartOptionsTimeLine(chart: any) {
    return {
      theme: "light2",
      animationEnabled: true,
      zoomEnabled: true,
      title: {
        text: ""
      },
      axisX: {
        title: "Year",
        valueFormatString: "YYYY",
        interval: 1,
      },
      axisY: {
        title: "Count",
      },
      data: [
        {
          type: "line", // Timeline is best represented as a line chart
          xValueFormatString: "YYYY",
          markerType: "circle", // Display dots on the line
          markerSize: 6, // Set the size of the dots
          // markerColor: "blue", // Change the color of the dots
          // lineColor: "green", // Cu
          // fillOpacity: 1.0, // Adds a semi-transparent fill color below the line
          // color: "yellow",
          dataPoints: chart.data[0].data.map(d => ({ x: new Date(d.year, 0), y: d.count })) // Convert year to date
        }
      ]
    };
  }
}
