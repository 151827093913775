import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SongsState } from './songs.state';
import { SongsSelectors } from './songs.selectors';
import {
  addSong,
  clearErrorMessage,
  deleteSong,
  getAlbums,
  getArtists,
  getProducers,
  getSongById,
  getSongs,
} from './songs.action';

@Injectable({
  providedIn: 'root',
})
export class SongsFacade {
  songs$ = this.songsSelectors.songs$;
  albums$ = this.songsSelectors.albums$;
  artists$ = this.songsSelectors.artists$;
  producers$ = this.songsSelectors.producers$;
  errorMessage$ = this.songsSelectors.errorMessage$;
  constructor(
    private store: Store<SongsState>,
    private songsSelectors: SongsSelectors,
  ) {}

  getSongs() {
    this.store.dispatch(getSongs());
  }

  getAlbums() {
    this.store.dispatch(getAlbums());
  }

  getArtists() {
    this.store.dispatch(getArtists());
  }

  getProducers() {
    this.store.dispatch(getProducers());
  }

  getSongById(songId: string) {
    this.store.dispatch(getSongById({ songId }));
  }

  addSong(song) {
    this.store.dispatch(addSong({ song }));
  }

  // updateSong(song) {
  //   this.store.dispatch(updateSong({ song }));
  // }

  deleteSong(songId: string) {
    this.store.dispatch(deleteSong({ songId }));
  }

  clearErrorMessage() {
    this.store.dispatch(clearErrorMessage());
  }
}
