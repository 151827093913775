import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CreateProducerRequest } from 'src/app/models/producers/create-producer-request.model';
import { Producer } from 'src/app/models/producers/producer.model';
import { UserData } from 'src/app/models/user-data.model';

@Component({
  selector: 'rap-add-edit-producer-ui',
  templateUrl: './add-edit-producer.component.html',
  styleUrls: ['./add-edit-producer.component.scss'],
})
export class AddEditProducerComponent {
  @Input() title: string;
  @Input() editableProducer: Producer;
  @Input() errorMessage: string;
  @Input() userDetails: UserData;
  @Output() addProducer = new EventEmitter<CreateProducerRequest>();

  producerForm: FormGroup;
  submitted: boolean = false;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.producerForm = this.formBuilder.group({
      name: new FormControl(null, [Validators.required]),
    });
  }

  ngOnChanges() {
    if (this.editableProducer) {
      this.producerForm.patchValue({
        name: this.editableProducer.name,
      });
    }
  }

  get f(): { [key: string]: AbstractControl } {
    return this.producerForm.controls;
  }

  addUpdateProducer() {
    this.submitted = true;
    if (this.producerForm.invalid) {
      return;
    }

    let createProducerRequest: CreateProducerRequest = {
      data: {
        name: this.producerForm.value.name,
      },
    };

    this.addProducer.emit(createProducerRequest);
  }
}
