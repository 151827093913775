import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environments';

@Injectable({
  providedIn: 'root'
})
export class CardsService {
  private readonly BASE_URL = 'baseUrl';
  private url: string;


  constructor(private httpClient: HttpClient) {
    this.url = environment.baseUrl;
  }

  getMyCards() {
    console.log(this.url + `v2/cards/my_cards`)
    return this.httpClient.get(
      this.url + `v2/cards/my_cards`,
    ).pipe(map((response: any) => response));

  }

  getCard(id: string) {
    console.log(this.url + `v2/cards/${id}`)
    return this.httpClient.get(
      this.url + `v2/cards/${id}`,
    ).pipe(map((response: any) => response));

  }

  deleteCard(id: string) {
    console.log(this.url + `v2/cards/${id}`)
    return this.httpClient.delete(
      this.url + `v2/cards/${id}`,
    ).pipe(map((response: any) => response));

  }

  putCard(id: string, data: any) {
    console.log(this.url + `v2/cards/${id}`)
    return this.httpClient.put(
      this.url + `v2/cards/${id}`, data
    ).pipe(map((response: any) => response));

  }
}
