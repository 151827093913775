<div class="label-container">
  <div>
    <label class="clickable-label" routerLink="/dashboard/explore"> Rap Almanac </label>
    <img src="../assets/images/filled.svg" />
  </div>
  <div>
    <label class="non-clickable-label">App</label>
    <img src="../assets/images/filled.svg" />
  </div>
  <label class="clickable-label" routerLink="/dashboard/roles">Roles</label>
  <div *ngIf="editableRole !== null">
    <img src="../assets/images/filled.svg" />
    <label class="non-clickable-label">{{ editableRole.id }}</label>
  </div>
  <div>
    <img src="../assets/images/filled.svg" />
    <label class="non-clickable-label">{{ isNew === true ? 'New' : 'Edit' }}</label>
  </div>
</div>
<div class="functions-section">
  <div class="section-title">
    {{ title }}
  </div>
  <div class="error-container" *ngIf="errorMessage">
    {{ errorMessage }}
  </div>
  <div class="card">
    <div class="card-body">
      <rap-spinner class="loader-overlay" *ngIf="isLoading"></rap-spinner>
      <form [formGroup]="roleForm" (ngSubmit)="addUpdateRole()">
        <div class="form-group">
          <label for="email">Name</label>
          <div class="input-container">
            <input type="text" class="form-control" [class.submitted]="submitted" formControlName="name" />
          </div>
          <div *ngIf="submitted && f['name'].errors" class="rap-error-text">
            <span *ngIf="f['name'].errors?.['required']">Name is required</span>
          </div>
        </div>
        <div class="form-group">
          <label for="email">Permissions</label>
          <div class="input-container">
            <ng-multiselect-dropdown #multiSelect [placeholder]="'Select Permissions'" [settings]="settings"
              [data]="permissions" [(ngModel)]="roleForm.value.permissions" [formControl]="roleForm.get('permissions')">
            </ng-multiselect-dropdown>
          </div>
          <div *ngIf="submitted && f['permissions'].errors" class="rap-error-text">
            <span *ngIf="f['permissions'].errors?.['required']">Permissions is required</span>
          </div>
        </div>



        <div class="role-action-btns">
          <button type="submit" class="btn btn-dark button">Save</button>
          <button type="button" class="btn btn-light action-button" (click)="onReset()">
            Reset
          </button>
          <button type="button" class="btn btn-light action-button" routerLink="/dashboard/roles">
            Cancel
          </button>
        </div>
      </form>
    </div>
  </div>
</div>