<div class="roster-section">

  <div class="card">
    <div class="card-body">
      <div class="section-title">
        {{ title }}
      </div>
      <form [formGroup]="rosterForm" (ngSubmit)="addUpdateRoster()">
        <div class="form-group">
          <label for="name">Name</label>
          <div class="input-container">
            <input type="text" class="form-control" [class.submitted]="submitted" formControlName="name" />
          </div>
          <div *ngIf="submitted && f['name'].errors" class="rap-error-text">
            <span *ngIf="f['name'].errors?.['required']">Name is required</span>
          </div>
        </div>
        <div class="form-group">
          <label for="cleanAudioOnly" class="checkbox-label">CLEAN AUDIO ONLY</label>
          <div class="tooltip-container">
            <img src="../assets/images/info.svg" alt="Info Icon" class="info-icon" />
            <!-- <img src="assets/search.png" alt="Info Icon" class="info-icon"> -->

          </div>
          <div class="checkbox-container">
            <input type="checkbox" formControlName="cleanAudioOnly" id="cleanAudioOnly" class="custom-checkbox"
              [class.submitted]="submitted" />
          </div>
        </div>
        <div class="roster-action-btns">
          <button type="submit" class="btn btn-dark" style="padding: 5px 50px;">Save</button>
          <button type="button" class="btn btn-light action-button">
            Reset
          </button>
          <button type="button" class="btn btn-light action-button" routerLink="/dashboard/rosters">
            Cancel
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<rap-students *ngIf="editableRoster" [students]="students"></rap-students>