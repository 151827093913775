import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { CreateStudentRequest } from 'src/app/models/students/create-student-request.model';
import { Student } from 'src/app/models/students/student.model';
import { UpdateStudentRequest } from 'src/app/models/students/update-student-request.model';

@Component({
  selector: 'rap-add-edit-students-ui',
  templateUrl: './add-edit-students.component.html',
  styleUrls: ['./add-edit-students.component.scss'],
})
export class AddEditStudentsComponent {
  @Input() editableStudent: Student;
  @Input() errorMessage: string;
  @Output() createNewStudentEmitter = new EventEmitter<CreateStudentRequest>();

  @Output() updateStudentEmitter = new EventEmitter<UpdateStudentRequest>();
  studentForm: FormGroup;
  submitted: boolean = false;

  constructor(private formBuilder: FormBuilder) {
    this.studentForm = this.formBuilder.group({
      studentName: [null, [Validators.required]],
      studentCode: [null, [Validators.required]],
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.studentForm.controls;
  }

  addUpdateStudent() {
    this.submitted = true;
    if (this.studentForm.valid) {
      if (!this.editableStudent) {
        this.addStudent();
      } else {
        this.updateStudent();
      }
    }
  }

  updateStudent() {
    this.submitted = true;
    let studentRequest: UpdateStudentRequest = {
      studentCode: this.editableStudent.id,
      name: this.studentForm.value.studentName,
      forceCleanAudio: this.studentForm.value.cleanAudioOnly,
    };
    this.updateStudentEmitter.emit(studentRequest);
  }
  addStudent() {
    let studentRequest: CreateStudentRequest = {
      code: this.studentForm.value.studentCode,
      firstName: this.studentForm.value.studentName,
      userName: this.studentForm.value.studentName,
    };
    this.createNewStudentEmitter.emit(studentRequest);
  }
}
