import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProducersFacade } from '../store/producers.facade';
import { UserFacade } from 'src/app/store/user/facade/user.facade';

@Component({
  selector: 'rap-add-edit-producer',
  templateUrl: './add-edit-producer.container.component.html',
})
export class AddEditProducerContainerComponent {
  producers$ = this.producersFacade.producers$;
  errorMessage$ = this.producersFacade.errorMessage$;
  userDetails$ = this.userFacade.userDetails$;
  title = 'Add Producer';
  constructor(
    private producersFacade: ProducersFacade,
    private userFacade: UserFacade,
    private route: ActivatedRoute,
  ) {
    this.route.params.subscribe((params) => {
      if (params['id']) {
        this.title = 'Edit Producer';
        this.producersFacade.getProducerById(params['id']);
      }
    });
  }

  ngOnDestroy() {
    this.producersFacade.clearErrorMessage();
  }

  addProducer(producer) {
    console.log('addUpdateProducer');
    this.producersFacade.addProducer(producer);
  }

  editProducer(producer) {
    // this.songsFacade.updateSong(song);
  }
}
