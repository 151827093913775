<div class="users-section">
  <div class="section-title">Users</div>
  <div class="user-actions">
    <button type="button" class="btn btn-dark button" routerLink="/dashboard/users/add">
      Add New
    </button>

    <!-- Bootstrap Dropdown -->
    <div class="dropdown d-inline-block">
      <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown"
        aria-expanded="false" [disabled]="selectedUsers.length === 0">
        {{ strAction }}
      </button>
      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <li><a class="dropdown-item" (click)="performAction('disable')">Disable</a></li>
        <li><a class="dropdown-item" (click)="performAction('delete')">Delete</a></li>
      </ul>
    </div>

  </div>


  <div class="users-table" *ngIf="users?.length > 0">
    <table class="table rounded-4 overflow-hidden" data-search="true" data-show-columns="true"
      data-show-multi-sort="true">
      <thead>
        <tr>
          <th scope="col">
            <div class="form-check">
              <input class="form-check-input" (change)="toggleSelectAll($event)" type="checkbox" value=""
                id="flexCheckDefault" />
            </div>
          </th>
          <th scope="col">Username</th>
          <th scope="col">E-mail</th>
          <th scope="col">First name</th>
          <th scope="col">Last name</th>
          <th scope="col">NCES Id</th>
          <th scope="col">Role</th>
          <th scope="col">Active</th>
          <th scope="col">Date Joined</th>
          <th scope="col">Verify</th>
          <th scope="col">Approve</th>
          <th scope="col">Delete</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let user of paginatedUsers" [class.selected]="isSelected(user)">
          <th scope="col">
            <div class="form-check">
              <input class="form-check-input" [checked]="isSelected(user)" (change)="toggleSelection(user)"
                type="checkbox" value="" id="flexCheckDefault" />
            </div>
          </th>
          <th scope="col">
            <a class="user-details-link" (click)="editUser(user)">
              {{user.userName}}
            </a>
          </th>
          <th scope="col">
            <a class="user-details-link" (click)="editUser(user)">
              {{user.email}}
            </a>
          </th>
          <th scope="col">
            <a class="user-details-link" (click)="editUser(user)">
              {{user.firstName}}
            </a>
          </th>
          <th scope="col">
            <a class="user-details-link" (click)="editUser(user)">
              {{user.lastName}}
            </a>
          </th>
          <th scope="col">{{user.ncesId}}</th>
          <th scope="col">{{user?.role?.name}}</th>
          <th scope="col">{{user.active}}</th>
          <th scope="col">{{user.createdAt | date}}</th>
          <th scope="col">{{user.emailVerified}}</th>
          <td>
            <span *ngIf="user.approved">{{user.approved}}</span>
            <button *ngIf="!user.approved" type="button" class="btn btn-light approve-user-btn"
              (click)="approveUserEmitter.emit(user.id)">
              Approve
            </button>
          </td>
          <td>
            <button type="button" class="btn btn-dark delete-user-btn" (click)="deleteUser(user.id)">
              Delete
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="pagination">
      <rap-pagination [collectionSize]="usersCount" [page]="page" [pageSize]="pageSize"
        (onPageChange)="onPageChange($event)"></rap-pagination>
    </div>
  </div>

  <div class="no-users-card" *ngIf="!users || users?.length === 0">
    No users found
  </div>
</div>