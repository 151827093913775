import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { passwordValidator } from '../shared/validators/password.validator';

@Injectable({
  providedIn: 'root',
})
export class CreateAccountPresenter {
  constructor(private formBuilder: FormBuilder) {}

  initializeCreateAccountForm() {
    return this.formBuilder.group(
      {
        email: [null, [Validators.required, Validators.email]],
        password: [null, [Validators.required, passwordValidator()]],
        confirmPassword: [null, [Validators.required]],
        firstName: [null, [Validators.required]],
        lastName: [null, [Validators.required]],
        userName: [null, [Validators.required]],
        dateOfBirth: [null, [Validators.required]],
      },
      { validator: this.passwordMatchValidator },
    );
  }

  passwordMatchValidator(group: FormGroup) {
    const password = group.get('password').value;
    const confirmPassword = group.get('confirmPassword').value;

    return password === confirmPassword ? null : { passwordsMismatch: true };
  }
}
