import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CreateSongRequest } from 'src/app/models/songs/create-song-request.model';
import { Song } from 'src/app/models/songs/song.model';
import { DropdownValues } from 'src/app/users/store/users.state';

@Component({
  selector: 'rap-add-edit-song-ui',
  templateUrl: './add-edit-song.component.html',
  styleUrls: ['./add-edit-song.component.scss'],
})
export class AddEditSongComponent {
  @Input() title: string;
  @Input() albums: DropdownValues[];
  @Input() artists: DropdownValues[];
  @Input() producers: DropdownValues[];
  @Input() editableSong: Song;
  @Input() errorMessage: string;
  @Output() addSong = new EventEmitter<CreateSongRequest>();

  songForm: FormGroup;

  submitted: boolean = false;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.songForm = this.formBuilder.group({
      title: new FormControl(null, [Validators.required]),
      lyrics: new FormControl([], [Validators.required]),
      typedBy: new FormControl([]),
      tags: new FormControl([]),
      feature: new FormControl([]),
      album: new FormControl([]),
      artist: new FormControl([], [Validators.required]),
      producer: new FormControl([]),
      language: new FormControl([], [Validators.required]),
      explicit: new FormControl(false),
    });
  }

  ngOnChanges() {
    if (this.editableSong) {
      this.songForm.patchValue({
        title: this.editableSong.title,
      });
    }
  }

  get f(): { [key: string]: AbstractControl } {
    return this.songForm.controls;
  }

  addUpdateSong() {
    this.submitted = true;
    if (this.songForm.invalid) {
      return;
    }
    const song: CreateSongRequest = {
      data: {
        title: this.f['title'].value,
        lyrics: this.f['lyrics'].value,
        typed_by: this.f['typedBy'].value,
        albumid: this.f['album'].value,
        artistid: this.f['artist'].value,
        producerid: this.f['producer'].value,
        language_iso: this.f['language'].value,
      },
    };
  }
}
