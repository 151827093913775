import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Song } from '../models/songs/song.model';
import { DeleteModalComponent } from '../shared/component/delete-modal/delete-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Producer } from '../models/producers/producer.model';

@Component({
  selector: 'rap-producers-ui',
  templateUrl: './producers.component.html',
  styleUrls: ['./producers.component.scss'],
})
export class ProducersComponent {
  @Input() producers: Producer[];
  @Output() deleteProducerEmitter = new EventEmitter<string>();
  @Output() createProducerEmitter = new EventEmitter();
  @Output() editProducerEmitter = new EventEmitter<Producer>();
  page = 1;
  pageSize = 10;

  constructor(private modalService: NgbModal) {}

  get paginatedProducers(): Producer[] {
    const start = (this.page - 1) * this.pageSize;
    const end = start + this.pageSize;
    return this.producers.slice(start, end);
  }

  onPageChange(page: number) {
    this.page = page;
  }

  editProducer(producer: Producer) {
    this.editProducerEmitter.emit(producer);
  }

  deleteProducer(producerId: string) {
    const modalRef = this.modalService.open(DeleteModalComponent);
    modalRef.componentInstance.itemId = producerId;
    modalRef.result.then((id) => {
      this.deleteProducerEmitter.emit(id);
    });
  }
}
