<div class="producers-section">
    <div class="section-title">Producers</div>
    <button type="button" class="btn btn-dark add-producer-btn" (click)="createProducerEmitter.emit()">
        Add new
    </button>

    <div class="producers-table" *ngIf="producers?.length > 0">
        <table class="table rounded-4 overflow-hidden">
            <thead>
                <tr>
                    <th scope="col">Producer Id</th>
                    <th scope="col">Name</th>
                    <th scope="col">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let producer of paginatedProducers">
                    <td scope="row">{{ producer?.producerid }}</td>
                    <td scope="row">{{ producer?.name }}</td>
                    <td>
                        <button type="button" class="btn btn-light edit-producers-btn" (click)="editProducer(producer)">
                            Edit
                        </button>
                        <button type="button" class="btn btn-dark delete-producers-btn"
                            (click)="deleteProducer(producer.producerid)">
                            Delete
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>

        <div class="pagination">
            <rap-pagination [collectionSize]="producers?.length" [page]="page" [pageSize]="pageSize"
                (onPageChange)="onPageChange($event)"></rap-pagination>
        </div>
    </div>

    <div class="card" *ngIf="producers?.length === 0">
        <div class="card-body">
            <div class="no-producers-found">No producers found</div>
        </div>
    </div>
</div>