<div class="label-container">
  <div>
    <label class="clickable-label" routerLink="/dashboard/explore"> Rap Almanac </label>
    <img src="../assets/images/filled.svg" />
  </div>

  <div>
    <label class="non-clickable-label">App</label>
    <img src="../assets/images/filled.svg" />
  </div>


  <label (click)="onAnalytics()" class="clickable-label">Analytics</label>

  <div *ngIf="viewAnalytics !== null">
    <img src="../assets/images/filled.svg" />
    <label class="non-clickable-label">{{ viewAnalytics?.id}}</label>
  </div>


</div>
<div class="analytics-section">
  <rap-spinner class="loader-overlay" *ngIf="isLoading"></rap-spinner>
  <div class="section-title"> {{ viewAnalytics === null ? 'Queries' : 'View Student Queries' }}</div>
  <div class="error-container" *ngIf="errorMessage">
    {{ errorMessage }}
  </div>
  <div class="search-container">
    <img src="../assets/images/search.svg" class="search-icon" />
    <input type="text" placeholder="Search..." class="search-input" [(ngModel)]="filters.search"
      (keydown.enter)="getSearchResults()">
  </div>

  <ng-container *ngIf="analytics?.length > 0">
    <div class="custom-container">
      <table class="table rounded-3 overflow-hidden">
        <thead>
          <tr>
            <th scope="col"> {{ viewAnalytics === null ? 'Student' : 'Query' }}</th>
            <th scope="col" *ngIf="viewAnalytics == null">Roster</th>
            <th scope="col" *ngIf="viewAnalytics == null">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let analytic of paginatedAnalytics">
            <td scope="row">{{ viewAnalytics === null ? analytic?.student?.firstName : analytic.query_string }}</td>
            <td *ngIf="viewAnalytics == null">{{ analytic?.roster?.name }}</td>
            <td *ngIf="viewAnalytics == null">
              <button type="button" class="btn btn-light view-queries-btn" (click)="viewQuery(analytic.id)">
                View Queries
              </button>
              <button type="button" class="btn btn-light delete-queries-btn" (click)="deleteQuery(analytic.id)">
                Delete
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>


    <div class="pagination">
      <rap-pagination [collectionSize]="analytics?.length" [page]="page" [pageSize]="pageSize"
        (onPageChange)="onPageChange($event)"></rap-pagination>
    </div>
  </ng-container>


</div>

<div style="padding: 20px; text-align: center; margin-top: 100px;"
  *ngIf="analytics?.length === 0 && viewAnalytics == null && !isLoading">
  <img src="../assets/images/question.svg" />
  <div class="no-match" *ngIf="filters.search !== ''"> We didn’t find a match for {{filters.search}}</div>
  <div class="no-match" *ngIf="filters.search === ''">No queries found </div>
</div>