import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, switchMap, tap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { SongsService } from '../songs.service';
import {
  addSong,
  deleteSong,
  deleteSongSuccess,
  getAlbums,
  getAlbumsSuccess,
  getArtists,
  getArtistsSuccess,
  getProducers,
  getProducersSuccess,
  getSongById,
  getSongByIdSuccess,
  getSongs,
  getSongsSuccess,
  songsError,
} from './songs.action';
import { Song } from 'src/app/models/songs/song.model';
import { DropdownValues } from 'src/app/users/store/users.state';

@Injectable()
export class SongsEffects {
  getSongs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSongs),
      switchMap(() =>
        this.songsService.getAllSongs().pipe(
          map((data: any) => {
            return getSongsSuccess({ songs: data.rows });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(
              songsError({
                errorMessage:
                  error.error.message ||
                  'Something went wrong, please try again later!',
              }),
            );
          }),
        ),
      ),
    ),
  );

  getAlbums$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAlbums),
      switchMap(() =>
        this.songsService.getAlbums().pipe(
          map((albums: DropdownValues[]) => {
            return getAlbumsSuccess({ albums });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(
              songsError({
                errorMessage:
                  error.error.message ||
                  'Something went wrong, please try again later!',
              }),
            );
          }),
        ),
      ),
    ),
  );

  getArtists$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getArtists),
      switchMap(() =>
        this.songsService.getArtists().pipe(
          map((artists: DropdownValues[]) => {
            return getArtistsSuccess({ artists });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(
              songsError({
                errorMessage:
                  error.error.message ||
                  'Something went wrong, please try again later!',
              }),
            );
          }),
        ),
      ),
    ),
  );

  getProducers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getProducers),
      switchMap(() =>
        this.songsService.getProducers().pipe(
          map((producers: DropdownValues[]) => {
            return getProducersSuccess({ producers });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(
              songsError({
                errorMessage:
                  error.error.message ||
                  'Something went wrong, please try again later!',
              }),
            );
          }),
        ),
      ),
    ),
  );

  getSongById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSongById),
      switchMap((action) =>
        this.songsService.getSongById(action.songId).pipe(
          map((song: Song) => {
            return getSongByIdSuccess({ song });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(
              songsError({
                errorMessage:
                  error.error.message ||
                  'Something went wrong, please try again later!',
              }),
            );
          }),
        ),
      ),
    ),
  );

  addSong$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addSong),
      mergeMap((action) =>
        this.songsService.addNewSong(action.song).pipe(
          map(() => {
            this.toastr.success('Song added successfullly', '', {
              positionClass: 'toast-top-center',
              closeButton: false,
            });
            this.router.navigate(['dashboard/contribution/songs']);
            return getSongs();
          }),
          catchError((error) => {
            return of(
              songsError({
                errorMessage:
                  error.error.message ||
                  'Something went wrong, please try again later!',
              }),
            );
          }),
        ),
      ),
    ),
  );

  deleteRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteSong),
      mergeMap((action) =>
        this.songsService.deleteSong(action.songId).pipe(
          map(() => {
            this.toastr.success('Song deleted successfullly', '', {
              positionClass: 'toast-top-center',
              closeButton: false,
            });
            return deleteSongSuccess({ songId: action.songId });
          }),
          catchError((error) => {
            return of(
              songsError({
                errorMessage:
                  error.error.message ||
                  'Something went wrong, please try again later!',
              }),
            );
          }),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private songsService: SongsService,
    private toastr: ToastrService,
    private router: Router,
  ) {}
}
