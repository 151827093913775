import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environments';
import { DropdownValues } from '../users/store/users.state';
import { Producer } from '../models/producers/producer.model';
import { CreateProducerRequest } from '../models/producers/create-producer-request.model';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProducersService {
  private readonly BASE_URL = 'baseUrl';
  private url: string;

  constructor(private httpClient: HttpClient) {
    this.url = environment.baseUrl;
  }

  getMyProducers() {
    return this.httpClient.get<Producer[]>(
      this.url + `v2/producers/producers/my-producers`,
    );
  }

  getAllProducers() {
    return this.httpClient.get<Producer[]>(this.url + `v2/producers/my-producers`);
  }

  getProducerById(producerId: string) {
    return this.httpClient.get<Producer>(
      this.url + `v2/producers/` + producerId,
    );
  }

  addNewProducer(newProducer: CreateProducerRequest) {
    return this.httpClient.post(this.url + `v2/producers`, newProducer);
  }

  //   updateRole(updatedRole: UpdateRoleRequest) {
  //     return this.httpClient.put(
  //       this.url + `v2/roles/` + updatedRole.id,
  //       updatedRole,
  //     );
  //   }

  deleteProducer(producerId: string) {
    return this.httpClient.delete(this.url + `v2/producers/` + producerId);
  }

  getProducersNew(endPoint: string) {
    return this.httpClient.get(endPoint).pipe(
      map((result) => {
        console.log(result)
        return result;
      }),
      catchError((error) => {
        return 'Something went wrong, please try again later!';
      }),
    )
  }

  addNewProducerNew(newProducer: any) {
    return this.httpClient.post(this.url + `v2/producers`, newProducer).pipe(
      map((result) => {
        console.log(result)
        return result;
      }),
      catchError((error) => {
        return 'Something went wrong, please try again later!';
      }),
    );
  }

  deleteProducerNew(producerId: string) {
    return this.httpClient.delete(this.url + `v2/producers/` + producerId).pipe(
      map((result) => {
        console.log(result)
        return result;
      }),
      catchError((error) => {
        return 'Something went wrong, please try again later!';
      }),
    );
  }
}
