<div class="student-section">
  <div class="section-title">
    {{ !editableStudent ? 'Add Student to Roster' : 'Edit Student' }}
  </div>
  <div class="error-container" *ngIf="errorMessage">
    {{ errorMessage }}
  </div>

  <div class="card">
    <div class="card-body">
      <form [formGroup]="studentForm" (ngSubmit)="addUpdateStudent()">
        <div class="form-group">
          <label for="email">Student Name</label>
          <div class="input-container">
            <input
              type="text"
              class="form-control"
              [class.submitted]="submitted"
              formControlName="studentName"
            />
          </div>
          <div
            *ngIf="submitted && f['studentName'].errors"
            class="rap-error-text"
          >
            <span *ngIf="f['studentName'].errors?.['required']"
              >Name is required</span
            >
          </div>
        </div>
        <div class="form-group">
          <label for="email">Student Code</label>
          <div class="input-container">
            <input
              type="text"
              class="form-control"
              [class.submitted]="submitted"
              formControlName="studentCode"
            />
          </div>
        </div>
        <div
          *ngIf="submitted && f['studentCode'].errors"
          class="rap-error-text"
        >
          <span *ngIf="f['studentCode'].errors?.['required']"
            >Code is required</span
          >
        </div>
        <div class="student-action-btns">
          <button type="submit" class="btn btn-dark save-student-btn">
            Save
          </button>
          <button type="button" class="btn btn-light reset-student-btn">
            Reset
          </button>
          <button
            type="button"
            class="btn btn-light cancel-student-btn"
            routerLink="/dashboard/studn"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
