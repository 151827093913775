import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { animate, keyframes, style, transition, trigger } from '@angular/animations';
import { transform } from '@babel/core';
import { INavbarData } from './helper';
import { Observable, Subscription } from 'rxjs';
import { UserService } from '../services/user.service';
import { environment } from 'src/environments/environments';
import { PermissionsMap, UserData } from '../models/user-data.model';
import { AuthService } from '../services/auth.service';

interface SideNavToggle {
  screenWidth: number;
  collapsed: boolean;
}

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('550ms',
          style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('550ms',
          style({ opacity: 0 }))
      ]),
    ]),
    trigger('fadeInOut', [
      transition(':enter', [
        animate('1000ms',
          keyframes([
            style({ transform: 'rotate(0deg)', offset: 0 }),
            style({ transform: 'rotate(2turn)', offset: 1 }),
          ]))
      ])
    ])
  ]
})
export class NavBarComponent {
  @Output() onToggleSideNav: EventEmitter<SideNavToggle> = new EventEmitter();
  // @Input() navData: INavbarData[];
  collapsed = false;
  screenWidth = 0;
  navData: INavbarData[] = [];
  multiple: boolean = false;
  permissionsMap: PermissionsMap;
  // private eventsSubscription: Subscription;

  // @Input() profile: Observable<void>;

  constructor(private router: Router, private userService: UserService, private authService: AuthService) { }

  ngOnInit() {
    this.screenWidth = window.innerWidth;
    const storedPermissions = this.authService.getUserPermissions();

    // Convert the string back to an object
    if (storedPermissions) {
      const userObj = JSON.parse(storedPermissions);
      console.log(userObj);
      this.permissionsMap = userObj;
      console.log(this.permissionsMap);
    }

    console.log(this.permissionsMap);
    this.updateUserPermissions();
    // this.eventsSubscription = this.profile.subscribe(() => this.doSomething());
  }

  updateUserPermissions() {
    this.navData = [
      {
        routeLink: '/dashboard/explore',
        icon: 'assets/images/explore.svg',
        label: 'Explore',
        target: "",
        externalLink: null,
        enabled: this.permissionsMap.BasicSearch == 1
      },
      // {
      //   routeLink: '/dashboard/rap-gpt',
      //   icon: 'bi bi-box',
      //   label: 'Rap GPT',
      //   target: "",
      //   externalLink: null
      // },
      // {
      //   routeLink: '/dashboard/bookmarks',
      //   icon: 'bi bi-bookmarks',
      //   label: 'My Bookmarks',
      //   target: "",
      //   externalLink: null
      // },
      {
        routeLink: '/dashboard/contributions',
        icon: 'assets/images/contributions.svg',
        label: 'My Contributions',
        target: "",
        externalLink: null,
        enabled: this.permissionsMap.ManageOwnAssets == 1,
        items: [
          {
            routeLink: '/dashboard/contributions/songs',
            label: 'Songs',
            target: "",
            enabled: true,
          },
          {
            routeLink: '/dashboard/contributions/artists',
            label: 'Artists',
            target: "",
            enabled: true,

          },
          {
            routeLink: '/dashboard/contributions/producers',
            label: 'Producers',
            target: "",
            enabled: true,

          },

          {
            routeLink: '/dashboard/contributions/albums',
            label: 'Albums',
            target: "",
            enabled: true,

          },
        ],
      },

      {
        routeLink: '/dashboard/rosters',
        icon: 'assets/images/carbon_flight-roster.svg',
        label: 'Roster',
        target: "",
        externalLink: null,
        enabled: this.permissionsMap.ManageRosters == 1
      },
      {
        routeLink: '/dashboard/analytics',
        icon: 'assets/images/carbon_analytics.svg',
        label: 'Analytics',
        target: "",
        externalLink: null,
        enabled: this.permissionsMap.ManageRosters == 1
      },
      {
        routeLink: '/dashboard/cards',
        icon: 'assets/images/cards.svg',
        label: 'Cards',
        target: "",
        externalLink: null,
        enabled: this.permissionsMap.Cards == 1
      },
      {
        routeLink: '/dashboard/database',
        icon: 'assets/images/database.svg',
        label: 'Database',
        target: "",
        externalLink: null,
        enabled: this.permissionsMap.ManageAllAssets == 1,
        items: [
          {
            routeLink: '/dashboard/database/songs',
            label: 'Songs',
            target: "",
            enabled: true
          },
          {
            routeLink: '/dashboard/database/artists',
            label: 'Artists',
            target: "",
            enabled: true
          },
          {
            routeLink: '/dashboard/database/producers',
            label: 'Producers',
            target: "",
            enabled: true
          },

          {
            routeLink: '/dashboard/database/albums',
            label: 'Albums',
            target: "",
            enabled: true
          },
        ],
      },
      {
        routeLink: '/dashboard/roles',
        icon: 'assets/images/roles.svg',
        label: 'Roles',
        target: "",
        externalLink: null,
        enabled: this.permissionsMap.ManageUsers == 1,
      },
      {
        routeLink: '/dashboard/functions',
        icon: 'assets/images/function.svg',
        label: 'Functions',
        target: "",
        externalLink: null,
        enabled: this.permissionsMap.ManageUsers == 1,
      },
      {
        routeLink: '/dashboard/users',
        icon: 'assets/images/manager.svg',
        label: 'User',
        target: "",
        externalLink: null,
        enabled: this.permissionsMap.ManageUsers == 1,
        items: [
          {
            routeLink: '/dashboard/users',
            label: 'User Managment',
            target: "",
            enabled: true
          },
        ]
      },
      {
        routeLink: '/privacy-notice',
        icon: 'assets/images/scales.svg',
        label: 'Terms of Use',
        target: "_blank",
        externalLink: false,
        enabled: true
      },
      {
        routeLink: '/privacy-notice',
        icon: 'assets/images/coins-hand.svg',
        label: 'Privacy Notice',
        target: "_blank",
        externalLink: false,
        enabled: true
      },
      {
        routeLink: environment.baseUrl + "docs", //"https://dev.api.rapalmanac.com/v2/docs",//
        icon: 'assets/images/code-square.svg',
        label: 'Api Docs',
        target: "_self",
        externalLink: true,
        enabled: true
      },
      {
        routeLink: '',
        icon: 'assets/images/globe-06.svg',
        label: 'RRL V.2.0',
        target: "",
        externalLink: false,
        enabled: true
      },

    ];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['profile']) {
      console.log('Profile123');
    }

  }

  doSomething() {
    console.log('Profile12356');
    // this.router.navigate(['/dashboard/profile']);
  }


  toggleCollapse() {
    this.collapsed = !this.collapsed;
    this.onToggleSideNav.emit({
      collapsed: this.collapsed,
      screenWidth: this.screenWidth,
    });
  }

  closeSidenav() {
    this.collapsed = true;
    this.onToggleSideNav.emit({
      collapsed: this.collapsed,
      screenWidth: this.screenWidth,
    });
  }

  handleClick(item: INavbarData): void {
    console.log("mmm")
    if (!this.multiple) {
      for (let modelItem of this.navData) {
        if (item !== modelItem && modelItem.expanded) {
          modelItem.expanded = false;
        }
      }
    }
    item.expanded = !item.expanded;

    console.log(item, "menu")
  }
  ngOnDestroy() {
    // this.eventsSubscription.unsubscribe();
  }

  // onProfile() {
  //   console.log('Profile');

  // }
}
