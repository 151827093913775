import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Roster } from '../models/rosters/rosters.model';
import { DeleteModalComponent } from '../shared/component/delete-modal/delete-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'rap-rosters-ui',
  templateUrl: './rosters.component.html',
  styleUrls: ['./rosters.component.scss'],
})
export class RostersComponent {
  @Input() rosters: Roster[];
  @Output() deleteAnalyticsEmitter = new EventEmitter<string>();
  @Output() manageStudentEmitter = new EventEmitter<Roster>();
  page = 1;
  pageSize = 10;
  isLoading = false;

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
    this.isLoading = true;
    console.log(this.isLoading)
    // console.log(this.rosters.length)
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['rosters'] && this.rosters) {
      this.isLoading = false;
      this.page = 1;
    } else {
      this.isLoading = true;
      this.rosters = [];
    }
    console.log(this.isLoading)
    // console.log(this.rosters.length)
  }


  get paginatedRosters(): Roster[] {
    const start = (this.page - 1) * this.pageSize;
    const end = start + this.pageSize;
    return this.rosters.slice(start, end);
  }

  onPageChange(page: number) {
    this.page = page;
  }

  deleteRoster(rosterId: string) {
    const modalRef = this.modalService.open(DeleteModalComponent);
    modalRef.componentInstance.itemId = rosterId;
    modalRef.result
      .then((id) => {
        this.deleteAnalyticsEmitter.emit(id);
      })
      .catch((error) => console.log(error));
  }

  manageStudent(roster: Roster) {
    this.manageStudentEmitter.emit(roster);
  }

  ngOnDestroy(): void {
    this.rosters = [];
    this.isLoading = false;
  }
}
