import { Component } from '@angular/core';
import { CardsService } from './cards.service';

@Component({
  selector: 'rap-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss']
})
export class CardsComponent {
  data = cardsData
  isLoading = false;

  chartOptions = {
    title: {
      text: '',
    },
    animationEnabled: true,
    axisY: {
      title: 'word count',
      gridThickness: 1,
      tickLength: 10,
    },
    data: [
      {
        type: 'column',
        cornerRadius: 10,
        dataPoints: [
          { label: "Item 1", y: 10, color: "#FF5733", cornerRadius: 5 },
          { label: "Item 2", y: 15, color: "#33FF57" },
          { label: "Item 3", y: 25, color: "#3357FF" },
          { label: "Item 4", y: 30, color: "#FF33A1" },
          { label: "Item 5", y: 28, color: "#A133FF" }
        ],
      },
    ],
  };

  constructor(private cardService: CardsService) {
    console.log(this.data.length);
  }

  ngOnInit(): void {
    // this.cardService.getMyCards().subscribe((result) => {
    //   console.log(result)
    // });

  }

  getChartOptions(chartData: any) {
    console.log(chartData.chartType)
    if (!chartData.data || !chartData.data[0] || chartData.data[0].data == null) {
      console.log('Invalid data structure:', chartData);
      return {}; // Return an empty object or handle error
    }

    // return {};

    return {
      title: {
        text: ""
      },
      axisY: {
        title: 'Word Count',
        gridThickness: 1,
        tickLength: 10,
      },
      axisX: {
        title: 'Artist'
      },
      data: [{
        type: 'column',
        maxBarThickness: 30,
        dataPoints: chartData.data[0].data.map((item: any) => ({
          label: item.artist,
          y: item.count
        }))
      }]
    };
  }

  getChartOptionsTimeLine(chart) {
    return {
      theme: "light2",
      animationEnabled: true,
      zoomEnabled: true,
      title: {
        text: ""
      },
      axisX: {
        title: "Year",
        valueFormatString: "YYYY",
        interval: 1,
      },
      axisY: {
        title: "Count",
      },
      data: [
        {
          type: "line", // Timeline is best represented as a line chart
          xValueFormatString: "YYYY",
          markerType: "circle", // Display dots on the line
          markerSize: 6, // Set the size of the dots
          // markerColor: "blue", // Change the color of the dots
          // lineColor: "green", // Cu
          // fillOpacity: 1.0, // Adds a semi-transparent fill color below the line
          // color: "yellow",
          dataPoints: chart.data[0].data.map(d => ({ x: new Date(d.year, 0), y: d.count })) // Convert year to date
        }
      ]
    };
  }

  getChartOptions3D(chart) {
    return {
      title: {
        text: "Nearest Neighbour Visualization"
      },
      axisX: {
        title: "X Axis"
      },
      axisY: {
        title: "Y Axis"
      },
      axisZ: {
        title: "Z Axis",
        minimum: -20,
        maximum: 30
      },
      data: {
        type: "scatter",
        name: chart.searchWord,
        showInLegend: true,
        dataPoints: chart.data[0].neighbors.map(neighbor => ({
          x: neighbor.x,
          y: neighbor.y,
          z: neighbor.z,
          label: neighbor.word,
          color: "blue" // Set color for points
        }))
      }
    };
  }

  onCards() {

  }

}

export const cardsData =
  [
    {
      "chartType": "Bar chart",
      "text": null,
      "searchQuery": "?include_terms[]=rap&page=1",
      "id": "f9256656-6606-4b64-804b-97e014c69e71",
      "path": null,
      "searchWord": "rap",
      "data": [
        {
          "word": "rap",
          "data": [
            {
              "count": 444,
              "artist": "Lil B",
              "numSongs": 1083
            },
            {
              "count": 372,
              "artist": "Too $hort",
              "numSongs": 328
            },
            {
              "count": 371,
              "artist": "Don't Flop",
              "numSongs": 118
            },
            {
              "count": 296,
              "artist": "JAY-Z",
              "numSongs": 648
            },
            {
              "count": 274,
              "artist": "Eminem",
              "numSongs": 605
            }
          ]
        }
      ]
    },
    {
      "chartType": "Bar chart",
      "text": null,
      "searchQuery": "?include_terms[]=test&page=1",
      "id": "94b70f96-e765-4b12-a247-ef60e7629d3f",
      "path": null,
      "searchWord": "test",
      "data": [
        {
          "word": "test",
          "data": [
            {
              "count": 86,
              "artist": "Bone Thugs-n-Harmony",
              "numSongs": 269
            },
            {
              "count": 75,
              "artist": "Jme",
              "numSongs": 149
            },
            {
              "count": 73,
              "artist": "2Pac",
              "numSongs": 786
            },
            {
              "count": 62,
              "artist": "K-Rino",
              "numSongs": 571
            },
            {
              "count": 60,
              "artist": "Inja",
              "numSongs": 16
            }
          ]
        }
      ]
    },
    {
      "chartType": "Bar chart",
      "text": null,
      "searchQuery": "?include_terms[]=rap&page=1",
      "id": "cb855dba-8c9c-491b-8db2-beb3319b59eb",
      "path": null,
      "searchWord": "rap",
      "data": [
        {
          "word": "rap",
          "data": [
            {
              "count": 444,
              "artist": "Lil B",
              "numSongs": 1083
            },
            {
              "count": 372,
              "artist": "Too $hort",
              "numSongs": 328
            },
            {
              "count": 371,
              "artist": "Don't Flop",
              "numSongs": 118
            },
            {
              "count": 296,
              "artist": "JAY-Z",
              "numSongs": 648
            },
            {
              "count": 274,
              "artist": "Eminem",
              "numSongs": 605
            }
          ]
        }
      ]
    },
    {
      "chartType": "Bar chart",
      "text": null,
      "searchQuery": "?include_terms[]=song&page=1",
      "id": "36f86157-7687-47f1-afb5-741a462d86d0",
      "path": null,
      "searchWord": "song",
      "data": [
        {
          "word": "song",
          "data": [
            {
              "count": 528,
              "artist": "Rap Critic",
              "numSongs": 44
            },
            {
              "count": 241,
              "artist": "PsyQonaut",
              "numSongs": 30
            },
            {
              "count": 202,
              "artist": "El Meswy",
              "numSongs": 164
            },
            {
              "count": 193,
              "artist": "Eminem",
              "numSongs": 605
            },
            {
              "count": 173,
              "artist": "Charles Hamilton",
              "numSongs": 534
            }
          ]
        }
      ]
    },
    {
      "chartType": "Nearest Neighbour",
      "text": null,
      "searchQuery": "?include_terms[]=song&page=1",
      "id": "45542c7d-72a0-41fd-b2f8-391e84a9c4ce",
      "path": null,
      "searchWord": "song",
      "data": [
        {
          "query": {
            "word": "song",
            "x": 19.696489334106445,
            "y": -11.159255981445312,
            "z": -17.994611740112305
          },
          "neighbors": [
            {
              "word": "message",
              "x": 6.360401153564453,
              "y": -4.881613731384277,
              "z": -12.07796859741211
            },
            {
              "word": "album",
              "x": 19.44540786743164,
              "y": -12.52987575531006,
              "z": -18.75219345092773
            },
            {
              "word": "tune",
              "x": 19.839088439941406,
              "y": -10.159987449645996,
              "z": -17.031301498413086
            },
            {
              "word": "mixtape",
              "x": 19.434162139892575,
              "y": -12.576006889343262,
              "z": -18.77939987182617
            },
            {
              "word": "music",
              "x": 20.2554931640625,
              "y": 5.29707145690918,
              "z": -13.13453483581543
            },
            {
              "word": "memoir",
              "x": 16.565839767456055,
              "y": -3.587180852890014,
              "z": -12.21342658996582
            },
            {
              "word": "songs",
              "x": 21.73828887939453,
              "y": -9.746017456054688,
              "z": -17.80120849609375
            },
            {
              "word": "melody",
              "x": 19.86492919921875,
              "y": -9.891443252563477,
              "z": -16.665691375732422
            },
            {
              "word": "recital",
              "x": 17.71727752685547,
              "y": -5.964467525482178,
              "z": -14.262365341186523
            },
            {
              "word": "poem",
              "x": 19.026199340820312,
              "y": -4.959409236907959,
              "z": -16.193134307861328
            }
          ]
        }
      ]
    },
    {
      "chartType": "Nearest Neighbour",
      "text": null,
      "searchQuery": "?include_terms[]=rap&page=1",
      "id": "b85b47e0-74b1-4c67-b4b5-ef7884c42ca4",
      "path": null,
      "searchWord": "rap",
      "data": [
        {
          "query": {
            "word": "rap",
            "x": -1.6974124908447266,
            "y": 16.368085861206055,
            "z": 9.191452026367188
          },
          "neighbors": [
            {
              "word": "crap",
              "x": -1.748129963874817,
              "y": -17.23493003845215,
              "z": 14.721842765808104
            },
            {
              "word": "boombap",
              "x": 16.598865509033203,
              "y": 3.141407966613769,
              "z": -10.681572914123535
            },
            {
              "word": "mumble",
              "x": -0.9224628210067748,
              "y": 15.555479049682615,
              "z": 9.690043449401855
            },
            {
              "word": "rapping",
              "x": -1.9916203022003172,
              "y": 16.690494537353516,
              "z": 9.10011386871338
            },
            {
              "word": "wack",
              "x": 7.909759521484375,
              "y": 21.191814422607425,
              "z": -2.368997812271118
            },
            {
              "word": "hiphop",
              "x": 20.00481986999512,
              "y": 5.4152679443359375,
              "z": -13.009035110473633
            },
            {
              "word": "freestyle",
              "x": 25.788101196289062,
              "y": -5.397254467010498,
              "z": -14.971759796142578
            },
            {
              "word": "rappin",
              "x": -2.048884630203247,
              "y": 16.74391746520996,
              "z": 9.09632682800293
            },
            {
              "word": "copycat",
              "x": 5.264441013336182,
              "y": 20.67767333984375,
              "z": 0.25696074962615967
            },
            {
              "word": "hardcore",
              "x": 19.21236801147461,
              "y": 5.850967884063721,
              "z": -12.590543746948242
            }
          ]
        }
      ]
    },
    {
      "chartType": "Bar chart",
      "text": null,
      "searchQuery": "?include_terms[]=rap&page=1",
      "id": "d1aaef0a-6f46-4b60-91ac-8cf09f2a4835",
      "path": null,
      "searchWord": "rap",
      "data": [
        {
          "word": "rap",
          "data": [
            {
              "count": 444,
              "artist": "Lil B",
              "numSongs": 1083
            },
            {
              "count": 372,
              "artist": "Too $hort",
              "numSongs": 328
            },
            {
              "count": 371,
              "artist": "Don't Flop",
              "numSongs": 118
            },
            {
              "count": 296,
              "artist": "JAY-Z",
              "numSongs": 648
            },
            {
              "count": 274,
              "artist": "Eminem",
              "numSongs": 605
            }
          ]
        }
      ]
    },
    {
      "chartType": "Nearest Neighbour",
      "text": null,
      "searchQuery": "?include_terms[]=rap&page=1",
      "id": "0738cfbb-d770-45c6-9787-79cc09fd79c8",
      "path": null,
      "searchWord": "rap",
      "data": [
        {
          "query": {
            "word": "rap",
            "x": -1.6974124908447266,
            "y": 16.368085861206055,
            "z": 9.191452026367188
          },
          "neighbors": [
            {
              "word": "crap",
              "x": -1.748129963874817,
              "y": -17.23493003845215,
              "z": 14.721842765808104
            },
            {
              "word": "boombap",
              "x": 16.598865509033203,
              "y": 3.141407966613769,
              "z": -10.681572914123535
            },
            {
              "word": "mumble",
              "x": -0.9224628210067748,
              "y": 15.555479049682615,
              "z": 9.690043449401855
            },
            {
              "word": "rapping",
              "x": -1.9916203022003172,
              "y": 16.690494537353516,
              "z": 9.10011386871338
            },
            {
              "word": "wack",
              "x": 7.909759521484375,
              "y": 21.191814422607425,
              "z": -2.368997812271118
            },
            {
              "word": "hiphop",
              "x": 20.00481986999512,
              "y": 5.4152679443359375,
              "z": -13.009035110473633
            },
            {
              "word": "freestyle",
              "x": 25.788101196289062,
              "y": -5.397254467010498,
              "z": -14.971759796142578
            },
            {
              "word": "rappin",
              "x": -2.048884630203247,
              "y": 16.74391746520996,
              "z": 9.09632682800293
            },
            {
              "word": "copycat",
              "x": 5.264441013336182,
              "y": 20.67767333984375,
              "z": 0.25696074962615967
            },
            {
              "word": "hardcore",
              "x": 19.21236801147461,
              "y": 5.850967884063721,
              "z": -12.590543746948242
            }
          ]
        }
      ]
    },
    {
      "chartType": "Timeline",
      "text": null,
      "searchQuery": "?include_terms[]=rap&page=1",
      "id": "b483f47a-b0ca-4446-945a-8fec984373f0",
      "path": null,
      "searchWord": "rap",
      "data": [
        {
          "word": "rap",
          "data": [
            {
              "year": 1998,
              "count": 605
            },
            {
              "year": 1991,
              "count": 198
            },
            {
              "year": 1995,
              "count": 311
            },
            {
              "year": 1996,
              "count": 451
            },
            {
              "year": 1993,
              "count": 172
            },
            {
              "year": 1992,
              "count": 206
            },
            {
              "year": 2001,
              "count": 701
            },
            {
              "year": 1999,
              "count": 795
            },
            {
              "year": 1994,
              "count": 261
            },
            {
              "year": 1997,
              "count": 493
            },
            {
              "year": 2000,
              "count": 741
            },
            {
              "year": 1980,
              "count": 1
            },
            {
              "year": 1981,
              "count": 55
            },
            {
              "year": 1983,
              "count": 16
            },
            {
              "year": 1984,
              "count": 19
            },
            {
              "year": 1985,
              "count": 112
            },
            {
              "year": 1986,
              "count": 22
            },
            {
              "year": 2002,
              "count": 903
            },
            {
              "year": 1987,
              "count": 55
            },
            {
              "year": 1988,
              "count": 250
            },
            {
              "year": 1989,
              "count": 264
            },
            {
              "year": 2003,
              "count": 1136
            },
            {
              "year": 1990,
              "count": 249
            },
            {
              "year": 2007,
              "count": 915
            },
            {
              "year": 2004,
              "count": 994
            },
            {
              "year": 2008,
              "count": 1181
            },
            {
              "year": 2012,
              "count": 2087
            },
            {
              "year": 2013,
              "count": 2462
            },
            {
              "year": 2010,
              "count": 1466
            },
            {
              "year": 2016,
              "count": 3039
            },
            {
              "year": 2018,
              "count": 2561
            },
            {
              "year": 2017,
              "count": 3737
            },
            {
              "year": 2005,
              "count": 1133
            },
            {
              "year": 2011,
              "count": 1852
            },
            {
              "year": 2006,
              "count": 1166
            },
            {
              "year": 2009,
              "count": 1246
            },
            {
              "year": 2015,
              "count": 2528
            },
            {
              "year": 2014,
              "count": 2218
            },
            {
              "year": 2019,
              "count": 46
            }
          ]
        }
      ]
    },
    {
      "chartType": "Bar chart",
      "text": null,
      "searchQuery": "?include_terms[]=rap&page=1",
      "id": "d7a529db-5660-4089-b3cd-ed1f04a6fd8b",
      "path": null,
      "searchWord": "rap",
      "data": [
        {
          "word": "rap",
          "data": [
            {
              "count": 444,
              "artist": "Lil B",
              "numSongs": 1083
            },
            {
              "count": 372,
              "artist": "Too $hort",
              "numSongs": 328
            },
            {
              "count": 371,
              "artist": "Don't Flop",
              "numSongs": 118
            },
            {
              "count": 296,
              "artist": "JAY-Z",
              "numSongs": 648
            },
            {
              "count": 274,
              "artist": "Eminem",
              "numSongs": 605
            }
          ]
        }
      ]
    }
  ];