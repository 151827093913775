import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, switchMap, tap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import {
  createNewRoster,
  createNewRosterSuccess,
  deleteRoster,
  deleteRosterSuccess,
  getRosterById,
  getRosterByIdSuccess,
  getRosters,
  getRostersSuccess,
  rostersError,
  updateRoster,
  updateRosterSuccess,
} from './rosters.action';
import { RostersService } from '../rosters.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Roster } from 'src/app/models/rosters/rosters.model';

@Injectable()
export class RostersEffects {
  getRosters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getRosters),
      switchMap(() =>
        this.rostersService.getRosters().pipe(
          map((data: any) => {
            return getRostersSuccess({ rosters: data.rows });
          }),
          catchError((error: HttpErrorResponse) => {
            return of();
          }),
        ),
      ),
    ),
  );

  getRosterById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getRosterById),
      switchMap((action) =>
        this.rostersService.getRosterById(action.rosterId).pipe(
          map((roster: Roster) => {
            return getRosterByIdSuccess({ roster });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(rostersError({ error: error.error.message || 'Something went wrong, please try again later!' }));
          }),
        ),
      ),
    ),
  );

  deleteRoster$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteRoster),
      mergeMap((action) =>
        this.rostersService.deleteRoster(action.rosterId).pipe(
          map(() => {
            this.toastr.success('Roster deleted successfullly', '', {
              positionClass: 'toast-top-center',
              closeButton: false,
            });
            return deleteRosterSuccess({ rosterId: action.rosterId });
          }),
          catchError(() => {
            return of();
          }),
        ),
      ),
    ),
  );

  createNewRoster$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createNewRoster),
      switchMap((action) =>
        this.rostersService.createNewRoster(action.newRoster).pipe(
          map((data: any) => {
            return createNewRosterSuccess();
          }),
          catchError((error: HttpErrorResponse) => {
            return of();
          }),
        ),
      ),
    ),
  );

  createNewRosterSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createNewRosterSuccess),
        tap(() => {
          this.toastr.success('Roster created successfully', '', {
            positionClass: 'toast-top-center',
            closeButton: false,
          });
          this.router.navigate(['/dashboard/rosters']);
        }),
      ),
    { dispatch: false },
  );

  updateRoster$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateRoster),
      mergeMap((action) =>
        this.rostersService.updateRoster(action.updatedRoster).pipe(
          map(() => {
            return updateRosterSuccess();
          }),
          catchError((error) => {
            console.log(error);
            return of();
          }),
        ),
      ),
    ),
  );

  updateRosterSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateRosterSuccess),
        tap(() => {
          this.toastr.success('Roster updated', '', {
            positionClass: 'toast-top-center',
            closeButton: false,
          });
          this.router.navigate(['/dashboard/rosters']);
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private rostersService: RostersService,
    private toastr: ToastrService,
    private router: Router,
  ) {}
}
