import { createAction, props } from '@ngrx/store';
import { CreateSongRequest } from 'src/app/models/songs/create-song-request.model';
import { Song } from 'src/app/models/songs/song.model';
import { DropdownValues } from 'src/app/users/store/users.state';

export const getSongs = createAction('[Songs] GET_SONGS');

export const getSongsSuccess = createAction(
  '[Songs] GET_SONGS_SUCCESS',
  props<{ songs: Song[] }>(),
);

export const getAlbums = createAction('[Songs] GET_ALBUMS');

export const getAlbumsSuccess = createAction(
  '[Songs] GET_ALBUMS_SUCCESS',
  props<{ albums: DropdownValues[] }>(),
);

export const getArtists = createAction('[Songs] GET_ARTISTS');

export const getArtistsSuccess = createAction(
  '[Songs] GET_ARTISTS_SUCCESS',
  props<{ artists: DropdownValues[] }>(),
);

export const getProducers = createAction('[Songs] GET_PRODUCERS');

export const getProducersSuccess = createAction(
  '[Songs] GET_PRODUCERS_SUCCESS',
  props<{ producers: DropdownValues[] }>(),
);

export const getSongById = createAction(
  '[Songs] GET_SONGS_BY_ID',
  props<{ songId: string }>(),
);

export const getSongByIdSuccess = createAction(
  '[Songs] GET_SONGS_SUCCESS_BY_ID',
  props<{ song: Song }>(),
);

export const addSong = createAction(
  '[Songs] ADD_SONG',
  props<{ song: CreateSongRequest }>(),
);

export const addSongSuccess = createAction(
  '[Songs] ADD_SONG_SUCCESS',
  props<{ song: Song }>(),
);

export const deleteSong = createAction(
  '[Songs] DELETE_SONG',
  props<{ songId: string }>(),
);

export const deleteSongSuccess = createAction(
  '[Songs] DELETE_SONG_SUCCESS',
  props<{ songId: string }>(),
);

export const songsError = createAction(
  '[Songs] SONGS_ERROR',
  props<{ errorMessage: string }>(),
);

export const clearErrorMessage = createAction('[Songs] SONGS_ERROR');
