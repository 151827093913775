import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Song } from '../models/songs/song.model';
import { DeleteModalComponent } from '../shared/component/delete-modal/delete-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'rap-songs-ui',
  templateUrl: './songs.component.html',
  styleUrls: ['./songs.component.scss']
})
export class SongsComponent {
  @Input() songs: Song[] = [];
  @Output() deleteSongEmitter = new EventEmitter<string>();
  @Output() createSongEmitter = new EventEmitter<any>();
  page = 1;
  pageSize = 10;
  isLoading = false;

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
    this.isLoading = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['songs'] && this.songs) {
      this.isLoading = false;
      this.page = 1;
    }
  }


  get paginatedSongs(): Song[] {
    const start = (this.page - 1) * this.pageSize;
    const end = start + this.pageSize;
    return this.songs.slice(start, end);
  }

  onPageChange(page: number) {
    this.page = page;
  }

  deleteSong(songId: string) {
    const modalRef = this.modalService.open(DeleteModalComponent);
    modalRef.componentInstance.itemId = songId;
    modalRef.result
      .then((id) => {
        this.deleteSongEmitter.emit(id);
      })
  }

  ngOnDestroy(): void {
    this.songs = [];
    this.isLoading = false;
  }
}
