import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UsersFacade } from './store/users.facade';
import { User } from '../models/users/user.model';

@Component({
  selector: 'rap-users',
  templateUrl: './users.container.component.html',
})
export class UsersContainerComponent {
  users$ = this.usersFacade.users$;
  usersCount$ = this.usersFacade.usersCount$;
  constructor(
    private router: Router,
    private usersFacade: UsersFacade,
  ) {}

  ngOnInit() {
    this.getUsers(1);
  }

  getUsers(page: number) {
    this.usersFacade.getUsers(page);
  }

  deleteUser(id: string) {
    this.usersFacade.deleteUser(id);
  }

  editUser(user: User) {
    this.router.navigate(['/dashboard/users/' + user.id + '/edit']);
  }

  approveUser(id: string) {
    this.usersFacade.approveUser(id);
  }

  performAction(data: any) {
    this.usersFacade.performAction(data);
  }
}
