import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { UpdateRosterRequest } from 'src/app/models/rosters/update-roster-request.model';
import { CreateRosterRequest } from 'src/app/models/rosters/create-roster-request.model';
import { Roster } from 'src/app/models/rosters/rosters.model';
import { StudentsState } from './students.state';
import {
  createNewStudent,
  deleteStudent,
  getStudents,
  resetErrorMessage,
  setEditableStudent,
  setRosterId,
  updateStudent,
} from './students.action';
import { StudentsSelectors } from './students.selectors';
import { CreateStudentRequest } from 'src/app/models/students/create-student-request.model';
import { Student } from 'src/app/models/students/student.model';
import { UpdateStudentRequest } from 'src/app/models/students/update-student-request.model';

@Injectable({
  providedIn: 'root',
})
export class StudentsFacade {
  students$ = this.studentsSelectors.students$;
  editableStudent$ = this.studentsSelectors.editableStudent$;
  rosterId$ = this.studentsSelectors.rosterId$;
  errorMessage$ = this.studentsSelectors.errorMessage$;
  constructor(
    private store: Store<StudentsState>,
    private studentsSelectors: StudentsSelectors,
  ) {}

  getStudents(rosterId: string) {
    this.store.dispatch(getStudents({ rosterId }));
  }

  deleteStudent(rosterId: string) {
    this.store.dispatch(deleteStudent({ rosterId }));
  }

  createNewStudent(newStudent: CreateStudentRequest) {
    this.store.dispatch(createNewStudent({ newStudent }));
  }

  setEditableStudent(student: Student) {
    this.store.dispatch(setEditableStudent({ student }));
  }

  updateStudent(updatedStudent: UpdateStudentRequest) {
    this.store.dispatch(updateStudent({ updatedStudent }));
  }

  setRosterId(rosterId: string) {
    this.store.dispatch(setRosterId({ rosterId }));
  }

  resetErrorMessge() {
    this.store.dispatch(resetErrorMessage());
  }
}
