import { Action, createReducer, on } from '@ngrx/store';
import { ExploreState, initialExploreState } from './explore.state';
import {
  searchResults,
  searchResultsSucess,
  searchWithLanguage,
  searchWithLocation,
  searchWithMoods,
  searchWithTimeframe,
  searchWithInclude,
  searchWithExlcude
} from './explore.action';

export const exploreReducer = (
  state: ExploreState | undefined,
  action: Action,
): ExploreState => reducer(state, action);

const reducer = createReducer(
  initialExploreState,
  on(searchResults, (state, { term }) => ({
    ...state,
    searchTerm: term,
  })),
  on(searchWithLanguage, (state, { language }) => ({
    ...state,
    selectedLanguage: language,
  })),

  on(searchWithInclude, (state, { include }) => ({
    ...state,
    selectedInclude: include,
  })),

  on(searchWithExlcude, (state, { exclude }) => ({
    ...state,
    selectedExclude: exclude,
  })),

  on(searchWithLocation, (state, { location }) => ({
    ...state,
    selectedLocation: location,
  })),

  on(searchWithTimeframe, (state, { startDate, endDate }) => ({
    ...state,
    selectedTimeframe: {
      startDate,
      endDate,
    },
  })),

  on(searchWithMoods, (state, { selectedMoods }) => ({
    ...state,
    selectedMoods,
  })),

  on(searchResultsSucess, (state, { searchResults }) => ({
    ...state,
    searchResults,
  })),
);
