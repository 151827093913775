<div class="label-container">
    <div>
        <label class="clickable-label" routerLink="/dashboard/explore"> Rap Almanac </label>
        <img src="../assets/images/filled.svg" />
    </div>
    <div>
        <label class="non-clickable-label">App</label>
        <img src="../assets/images/filled.svg" />
    </div>

    <div>
        <label class="clickable-label" routerLink="/dashboard/users">Users</label>
        <!-- <img src="../assets/images/filled.svg" /> -->
    </div>
    <!-- <label class="clickable-label" routerLink="/dashboard/roles">Roles</label> -->
    <div *ngIf="editableUser !== null">
        <img src="../assets/images/filled.svg" />
        <label class="non-clickable-label">{{ editableUser.id }}</label>
    </div>
    <div>
        <img src="../assets/images/filled.svg" />
        <label class="non-clickable-label">Edit</label>
    </div>
</div>

<div class="user-section">
    <div class="section-title">
        {{ title }}
    </div>
    <div class="error-container" *ngIf="errorMessage">
        {{ errorMessage }}
    </div>
    <div class="card">
        <div class="card-body">
            <rap-spinner class="loader-overlay" *ngIf="isLoading"></rap-spinner>
            <form [formGroup]="userForm" (ngSubmit)="addUpdateUser()">
                <div class="form-group">
                    <label for="firstName">First Name</label>
                    <div class="input-container">
                        <input type="text" class="form-control" [class.submitted]="submitted"
                            formControlName="firstName" />
                    </div>
                    <div *ngIf="submitted && f['firstName'].errors" class="rap-error-text">
                        <span *ngIf="f['firstName'].errors?.['required']">First name is required</span>
                    </div>
                </div>
                <div class="form-group">
                    <label for="studentRoster">Student Roster</label>
                    <!-- <div class="input-container">
                        <select class="form-control" formControlName="studentRoster" [class.submitted]="submitted">
                            <option value="" disabled selected>Select Roster</option>
                            <option *ngFor="let roster of rosters" [value]="roster.id">
                                {{ roster.label }}
                            </option>
                        </select>
                    </div> -->
                    <div class="input-container">
                        <ng-multiselect-dropdown [placeholder]="'Select...'" [settings]="settings" [data]="rosters"
                            [(ngModel)]="userForm.value.studentRoster" [formControl]="userForm.get('studentRoster')">
                        </ng-multiselect-dropdown>

                    </div>
                    <!-- <div *ngIf="submitted && f['studentRoster'].errors" class="rap-error-text">
                        <span *ngIf="f['studentRoster'].errors?.['required']">Student roster is required</span>
                    </div> -->
                </div>
                <div class="form-group">
                    <label for="lastame">Last Name</label>
                    <div class="input-container">
                        <input type="text" class="form-control" [class.submitted]="submitted"
                            formControlName="lastName" />
                    </div>
                    <div *ngIf="submitted && f['lastName'].errors" class="rap-error-text">
                        <span *ngIf="f['lastName'].errors?.['required']">Last name is required</span>
                    </div>
                </div>
                <div class="form-group">
                    <label for="firstName">User Name</label>
                    <div class="input-container">
                        <input type="text" class="form-control" [class.submitted]="submitted"
                            formControlName="userName" />
                    </div>
                    <div *ngIf="submitted && f['userName'].errors" class="rap-error-text">
                        <span *ngIf="f['userName'].errors?.['required']">User name is required</span>
                    </div>
                </div>
                <div class="form-group">
                    <label for="email">Email</label>
                    <div class="input-container">
                        <input type="email" class="form-control" [class.submitted]="submitted"
                            [readonly]="editableUser !== null ? true : false" formControlName="email" />
                    </div>
                    <div *ngIf="submitted && f['email'].errors" class="rap-error-text">
                        <span *ngIf="f['email'].errors?.['required']">Email is required</span>
                    </div>
                </div>
                <div class="form-group">
                    <label for="role">Role</label>
                    <!-- <div class="input-container">
                        <select class="form-control" formControlName="role" [class.submitted]="submitted">
                            <option value="" disabled selected>Select Role</option>
                            <option *ngFor="let role of roles" [value]="role.id">
                                {{ role.label }}
                            </option>
                        </select>
                    </div> -->
                    <div class="input-container">
                        <ng-multiselect-dropdown [placeholder]="'Select...'" [settings]="rolesSettings" [data]="roles"
                            [(ngModel)]="userForm.value.role" [formControl]="userForm.get('role')">
                        </ng-multiselect-dropdown>

                    </div>
                    <div *ngIf="submitted && f['role'].errors" class="rap-error-text">
                        <span *ngIf="f['role'].errors?.['required']">Role is required</span>
                    </div>
                </div>
                <div class="form-group">
                    <label for="active">Active</label>
                    <div class="form-check">
                        <input class="form-check-input" formControlName="active" type="checkbox" value=""
                            id="flexCheckDefault" />
                    </div>
                </div>
                <div class="form-group">
                    <label for="allowMapperDelight">Allow Mapper Delight</label>
                    <div class="form-check">
                        <input class="form-check-input" formControlName="allowMapperDelight" type="checkbox" value=""
                            id="flexCheckDefault" />
                    </div>
                </div>

                <div class="role-action-btns">
                    <button type="submit" class="btn btn-dark" style="padding: 5px 50px;">Save</button>
                    <button type="button" class="btn btn-light action-button" (click)="onReset()">
                        Reset
                    </button>
                    <button type="button" class="btn btn-light action-button" routerLink="/dashboard/users">
                        Cancel
                    </button>
                </div>

            </form>
        </div>
    </div>
</div>