import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ProducersFacade } from './store/producers.facade';
import { Producer } from '../models/producers/producer.model';

@Component({
  selector: 'rap-producers',
  templateUrl: './producers.container.component.html',
  styleUrls: ['./producers.container.component.scss'],
})
export class ProducersContainerComponent {
  producers$ = this.producersFacade.producers$;

  constructor(
    private producersFacade: ProducersFacade,
    private router: Router,
  ) {}

  ngOnInit() {
    this.getAllProducers();
  }

  getMyProducers() {
    this.producersFacade.getMyProducers();
  }

  getAllProducers() {
    this.producersFacade.getAllProducers();
  }

  createProducers() {
    this.router.navigate(['/dashboard/contribution/producers/add']);
  }

  editProducers(producer: Producer) {
    this.router.navigate([
      '/dashboard/contribution/producers/' + producer.producerid + '/edit',
    ]);
  }

  deleteProducers(producerId: string) {
    this.producersFacade.deleteProducer(producerId);
  }
}
