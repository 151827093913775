import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StudentsFacade } from './store/students.facade';
import { Student } from '../models/students/student.model';

@Component({
  selector: 'rap-students',
  templateUrl: './students.container.component.html',
})
export class StudentsContainerComponent {
  rosterId: string;
  @Input() students: Student[];
  students$ = this.stduentsFacade.students$;

  constructor(
    private stduentsFacade: StudentsFacade,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.route.params.subscribe((params) => {
      this.rosterId = params['id'];
      this.stduentsFacade.setRosterId(this.rosterId);
    });
  }

  ngOnInit() {}

  getStudents(rosterId: string) {
    this.stduentsFacade.getStudents(rosterId);
  }

  deleteStudent(id: string) {
    this.stduentsFacade.deleteStudent(id);
  }

  manageStudent(student: Student) {
    this.stduentsFacade.setEditableStudent(student);
    this.router.navigate(['/dashboard/rosters/manage-students']);
  }
}
