import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'rap-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss'],
})
export class DeleteModalComponent {
  @Input() itemId: string;
  @Input() modalTitle: string = 'Confirm delete';
  @Input() modalBodyText: string =
    'Are you sure you want to delete thie item ?';
  constructor(public activeModal: NgbActiveModal) {}
}
