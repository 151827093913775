import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environments';
import { LoginCeredentials } from '../models/login-ceredentials.model';
import { LoginResposne } from '../models/login-response.model';
import { UserData } from '../models/user-data.model';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly BASE_URL = 'baseUrl';
  private url: string;

  private userDataSubject = new BehaviorSubject<UserData>(null);
  userData$ = this.userDataSubject.asObservable();
  userData: UserData;

  constructor(private httpClient: HttpClient) {
    this.url = environment.baseUrl;
  }


  authorizeUser(ceredentials: LoginCeredentials) {
    return this.httpClient.post<LoginResposne>(
      this.url + `auth/v2/signin/local`,
      ceredentials,
    );
  }

  getUserDetails() {
    return this.httpClient.get<UserData>(this.url + `auth/v2/me`);
  }

  createNewAccount(newAccount: any) {
    return this.httpClient.post(this.url + `auth/v2/signup`, newAccount, {
      responseType: 'text',
    });
  }

  verifyEmail(code, email) {
    return this.httpClient.post(this.url + `auth/v2/verify-email`, {
      code,
      email,
    });
  }

  sendPwdResetCode(email: string) {
    return this.httpClient.post<any>(
      this.url + `auth/v2/send-password-reset-code`,
      { email },
    );
  }

  resetPassword(newPassword) {
    return this.httpClient.put<string>(
      this.url + `auth/v2/password-reset`,
      newPassword,
    );
  }

  getOnBoardingDetails(id: string) {
    return this.httpClient.get<UserData>(this.url + `v2/users/` + id);
  }

  onBoardingSkip(data: any, id: string) {
    console.log(this.url + `v2/users/` + id)
    return this.httpClient.put(
      this.url + `v2/users/` + id,
      data,
    ).pipe(map((response: any) => response.data));
    // return this.httpClient.get<UserData>(this.url + `auth/v2/me`).pipe(map((response: any) => response.data));
  }

  stripePaymentSuccess(id: string) {
    console.log(this.url + `stripe_payment/success?session_id=` + id)
    return this.httpClient.get(
      this.url + `stripe_payment/success?session_id=` + id,
    ).pipe(map((response: any) => response));

  }

  getProfileDetails(id: string) {
    return this.httpClient.get<UserData>(this.url + `v2/users/` + id);
  }

  putProfileDetails(id: string, data: any) {
    return this.httpClient.put(
      this.url + `v2/users/` + id,
      data,
    ).pipe(map((response: any) => response.data));
  }

  postAvatar(id: string, data: any) {
    return this.httpClient.post(
      this.url + `v2/users/avatar/` + id,
      data,
    );
  }

  changePassword(data: string) {
    return this.httpClient.post(
      this.url + `auth/v2/send-password-reset-code`,
      data,
    ).pipe(map((response: any) => response));
  }

  updatePassword(newPassword: any) {
    return this.httpClient.put<string>(
      this.url + `auth/v2/password-reset`,
      newPassword,
    ).pipe(map((response: any) => response));;
  }

  updateUserData(data: UserData) {
    this.userDataSubject.next(data);
    this.userData = data;
  }

}
