import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SongsFacade } from './store/songs.facade';

@Component({
  selector: 'rap-songs',
  templateUrl: './songs.container.component.html',
  styleUrls: ['./songs.container.component.scss'],
})
export class SongsContainerComponent {
  songs$ = this.songsFacade.songs$;

  constructor(
    private songsFacade: SongsFacade,
    private router: Router,
  ) {}

  ngOnInit() {
    this.getSongs();
  }

  getSongs() {
    this.songsFacade.getSongs();
  }

  createSong() {
    this.router.navigate(['/dashboard/contribution/songs/add']);
  }

  deleteSong(songId: string) {
    this.songsFacade.deleteSong(songId);
  }
}
