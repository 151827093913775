import { Component } from '@angular/core';
import { StudentsFacade } from 'src/app/students/store/students.facade';
import { CreateStudentRequest } from 'src/app/models/students/create-student-request.model';
import { UpdateStudentRequest } from 'src/app/models/students/update-student-request.model';

@Component({
  selector: 'rap-add-edit-students',
  templateUrl: './add-edit-students.container.component.html',
})
export class AddEditStudentsContainerComponent {
  editableStudent$ = this.studentsFacade.editableStudent$;
  errorMessage$ = this.studentsFacade.errorMessage$;
  constructor(private studentsFacade: StudentsFacade) {}

  createNewStudent(newStudent: CreateStudentRequest) {
    this.studentsFacade.createNewStudent(newStudent);
  }

  updateStudent(updatedStudent: UpdateStudentRequest) {
    this.studentsFacade.updateStudent(updatedStudent);
  }

  ngOnDestroy() {
    this.studentsFacade.resetErrorMessge();
  }
}
