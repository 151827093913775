import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  authorize,
  authorizeFailure,
  createNewAccount,
  createNewAccountSuccess,
  getUserDetails,
  getUserDetailsSuccess,
  resetPassword,
  resetPasswordSuccess,
  sendPwdResetCode,
  sendPwdResetCodeFailure,
  sendPwdResetCodeSuccess,
  userAuthorizeError,
  verifyEmail,
  verifyEmailSuccess,
  getOnBoardingDetails,
  getOnBoardingSuccess
} from '../actions/user.action';
import {
  EMPTY,
  catchError,
  map,
  of,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { LoginResposne } from 'src/app/models/login-response.model';
import { AuthService } from 'src/app/services/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { UserFacade } from '../facade/user.facade';
import { ToastrService } from 'ngx-toastr';
import { PasswordResetSuccessModalComponent } from 'src/app/password-help/password-reset-success-modal/password-reset-success-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class UserEffects {
  authorize$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authorize),
      switchMap((payload) =>
        this.userService.authorizeUser(payload.ceredentials).pipe(
          map((data: LoginResposne) => {
            if (data.newPasswordRequired) {
              // nagivate to reset password screen
              this.router.navigate(['/password-help']);
              this.toastr.success(
                'Your password has expired, please reset your password.',
                '',
                {
                  positionClass: 'toast-top-center',
                  closeButton: false,
                  timeOut: 4500,
                },
              );
              return { type: 'NAVIGATE_TO_PASSWORD_HELP' };
            } else {
              this.authService.storeAccessToken(data.token);
              this.authService.storeUserId(data.id);
              return getUserDetails();
            }
          }),
          catchError((error: HttpErrorResponse) => {
            return of(authorizeFailure(error));
          }),
        ),
      ),
    ),
  );

  userDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserDetails),
      switchMap(() =>
        this.userService.getUserDetails().pipe(
          map((data) => getUserDetailsSuccess({ data })),
          catchError(() => EMPTY),
        ),
      ),
    ),
  );

  getUserDetailsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(getUserDetailsSuccess),
        tap((data) => {
          this.authService.storeUserEmail(data.data.email);
          this.authService.storeUserName(data.data.firstName);
          this.authService.storeUserAvatar(data.data.avatarPath ?? null);
          this.authService.storeUserPermissions(JSON.stringify(data.data.permissionsMap) ?? null);
          this.userService.updateUserData(data.data);
          console.log(data.data.skipOnboarding)
          if (data.data.skipOnboarding != null && data.data.skipOnboarding == false) {
            this.router.navigate(['/onboarding']);
          } else {
            this.router.navigate(['/dashboard/explore']).then(() => {
              history.replaceState(null, '', '/dashboard/explore');
            });
          }

        }),
      ),
    { dispatch: false },
  );

  sendPwdResetCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendPwdResetCode),
      switchMap((payload) =>
        this.userService.sendPwdResetCode(payload.email).pipe(
          map((data: any) => {
            this.toastr.success(
              'Please use the code sent to your registered email to reset password.',
              '',
              {
                positionClass: 'toast-top-center',
                closeButton: false,
                timeOut: 3500,
              },
            );
            return sendPwdResetCodeSuccess();
          }),
          catchError((error: HttpErrorResponse) => {
            return of(authorizeFailure({ error: error.error.error }));
          }),
        ),
      ),
    ),
  );

  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resetPassword),
      switchMap((payload) =>
        this.userService.resetPassword(payload.newPassword).pipe(
          map(() => {
            return resetPasswordSuccess();
          }),
        ),
      ),
      catchError((error: HttpErrorResponse) => {
        return of(authorizeFailure({ error: error.error.error }));
      }),
    ),
  );

  resetPasswordSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(resetPasswordSuccess),
        tap(() => {
          const modalRef = this.modalService.open(
            PasswordResetSuccessModalComponent,
          );
          modalRef.componentInstance.modalTitle = 'Success';
          modalRef.componentInstance.modalBodyText =
            'Your password has been reset';
          modalRef.componentInstance.moveToSignin = true;
        }),
      ),
    { dispatch: false },
  );

  createNewAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createNewAccount),
      switchMap((payload) =>
        this.userService.createNewAccount(payload.newAccountFormGroup).pipe(
          map((data: string) => {
            return createNewAccountSuccess();
          }),
          catchError((error: HttpErrorResponse) => {
            return of(authorizeFailure(error));
          }),
        ),
      ),
    ),
  );

  createNewAccountSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createNewAccountSuccess),
        tap(() => {
          this.router.navigate(['/verify-email']);
          this.toastr.success(
            'Please verify your email by entering the code sent to your registered email.',
            '',
            {
              positionClass: 'toast-top-center',
              closeButton: false,
              timeOut: 3500,
            },
          );
        }),
      ),
    { dispatch: false },
  );

  verifyEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(verifyEmail),
      withLatestFrom(this.userFacade.newAccountForm$),
      switchMap(([payload, newAccForm]) =>
        this.userService.verifyEmail(payload.code, newAccForm.email).pipe(
          map(() => {
            return verifyEmailSuccess();
          }),
          catchError((error: HttpErrorResponse) => {
            return of(authorizeFailure(error));
          }),
        ),
      ),
    ),
  );

  verifyEmailSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(verifyEmailSuccess),
        tap(() => {
          this.router.navigate(['/signin']);
          // this.router.navigate(['/onboarding']);
          this.toastr.success('Email verified successfully.', '', {
            positionClass: 'toast-top-center',
            closeButton: false,
            timeOut: 3500,
          });
        }),
      ),
    { dispatch: false },
  );

  userAuthorizeError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userAuthorizeError),
        tap(() => {
          this.router.navigate(['/signin']);
        }),
      ),
    { dispatch: false },
  );

  onBoardingDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserDetails),
      switchMap(() =>
        this.userService.getUserDetails().pipe(
          map((data) => getOnBoardingSuccess({ data })),
          catchError(() => EMPTY),
        ),
      ),
    ),
  );

  getOnBoardingSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(getUserDetailsSuccess),
        tap(() => {
          console.log()
          // this.router.navigate(['/dashboard/explore']).then(() => {
          //   history.replaceState(null, '', '/dashboard/explore');
          // });
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private userService: UserService,
    private userFacade: UserFacade,
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService,
    private modalService: NgbModal,
  ) { }
}
