import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  searchResults,
  searchResultsSucess,
  searchWithFilters,
  searchWithLanguage,
} from './explore.action';
import { catchError, map, mergeMap, of, switchMap, withLatestFrom } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { getFunctionsSuccess } from 'src/app/functions/store/functions.action';
import { UserService } from 'src/app/services/user.service';
import { ExploreService } from '../explore.service';
import { ExploreFacade } from './explopre.facade';

@Injectable()
export class ExploreEffects {
  // searchResults$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(searchResults),
  //     switchMap((action) =>
  //       this.exploreService
  //         .search(`v2/songs/search?include_terms[]=` + action.term + `&page=1`)
  //         .pipe(
  //           map((data: any) => {
  //             return searchResultsSucess({ searchResults: data });
  //           }),
  //           catchError((error: HttpErrorResponse) => {
  //             return of();
  //           }),
  //         ),
  //     ),
  //   ),
  // );

  searchWithFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchWithFilters),
      withLatestFrom(
        this.exploreFacade.searchTerm$,
        this.exploreFacade.languages$,
        this.exploreFacade.timeframes$,
        this.exploreFacade.moods$,
        this.exploreFacade.location$,
        this.exploreFacade.include$,
        this.exploreFacade.exclude$
      ),
      switchMap(([action, searchTerm, language, timeframe, moods, location, include, exclude]) => {
        return this.exploreService
          .search(getUrl(searchTerm, language, timeframe, moods, location, include, exclude))
          .pipe(
            map((data: any) => {
              console.log(data)
              console.log(include)
              console.log(exclude)
              return searchResultsSucess({ searchResults: data });
            }),
            catchError((error: HttpErrorResponse) => {
              return of();
            }),
          );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private exploreFacade: ExploreFacade,
    private exploreService: ExploreService,
  ) { }
}

function getUrl(searchTerm, language, timeframe, moods, location, include, exclude) {
  let url = `v2/songs/search?page=1`;
  console.log(include)
  console.log(exclude)
  if (searchTerm) {
    url += `&include_terms[]=` + searchTerm;
  }
  if (language) {
    url += `&lang=` + language;
  }

  if (location) {
    url += `&country=` + location;
  }



  if (include) {
    url += include.map(term => `&include_terms[]=${encodeURIComponent(term)}`).join('');
  }

  if (exclude) {
    url += exclude.map(term => `&exclude_terms[]=${encodeURIComponent(term)}`).join('');

  }

  if (timeframe) {
    url +=
      `&start_date=` + timeframe.startDate + `&end_date=` + timeframe.endDate;
  }

  if (moods) {
    Object.keys(moods).forEach((key) => {
      url +=
        `&` +
        moods[key].name +
        `[gte]=` +
        moods[key].range.value +
        `&` +
        moods[key].name +
        `[lte]=` +
        moods[key].range.highValue;
    });
  }

  return url;
}
