<div class="explore-section">
  <div class="section-title">{{title}}</div>
  <div class="filters">
    <rap-spinner class="loader-overlay" *ngIf="isLoading"></rap-spinner>
    <div class="filter">
      <button class="filter-btn" [ngClass]="{'active': showReset === false}">All</button>
    </div>
    <!-- Mood -->
    <div class="filter" *ngIf="permissionsMap.AdvancedSearch === 1">
      <button class="filter-btn" [ngClass]="{'active': selectedMoods.length > 0}" (click)="showDropdownFilters('mood')">
        Mood
        <img
          [src]="selectedMoods.length > 0 ? '../assets/images/white-chevron-down.svg' : '../assets/images/chevron-down.svg'" />

      </button>
      <div class="dropdown-container" *ngIf="dropdownFilter.showMoodDropdown">
        <div class="dropdown-header">
          <span>Mood</span>
          <button (click)="showDropdownFilters('mood')" type="button" class="btn"
            style="font-size: 13px;font-style: normal;font-weight: 600;">
            Cancel
          </button>
        </div>
        <div class="dropdown-content">
          <!-- Joy -->
          <div class="mood-filter">
            <div class="form-check">
              <input class="form-check-input" [checked]="moodFilters[0].checked" type="checkbox" name="mood" id="joy"
                value="Joy" (change)="handleMoodChange($event, 'joy')" />
              <label class="form-check-label" for="joy">Joy</label>
            </div>
            <i *ngIf="!moodFilters[0].checked" class="bi bi-chevron-right"></i>
            <!-- <i *ngIf="moodFilters[0].checked" class="bi bi-chevron-down"></i> -->
            <img *ngIf="moodFilters[0].checked" src="../assets/images/chevron-down.svg" />
          </div>
          <ng-container *ngIf="moodFilters[0].checked">
            <div class="range-slider-container">
              <div class="range-slider-labels">
                <span>Very Low</span>
                <span>Very High</span>
              </div>
              <ng5-slider [(value)]="moodFilters[0].range.value" [(highValue)]="moodFilters[0].range.highValue"
                [options]="sliderOptions"></ng5-slider>

            </div>
          </ng-container>
          <!-- Anger -->
          <div class="mood-filter">
            <div class="form-check">
              <input class="form-check-input" [checked]="moodFilters[1].checked" type="checkbox" name="mood" id="anger"
                value="Anger" (change)="handleMoodChange($event, 'anger')" />
              <label class="form-check-label" for="anger">Anger</label>
            </div>
            <i *ngIf="!moodFilters[1].checked" class="bi bi-chevron-right"></i>
            <!-- <i *ngIf="moodFilters[1].checked" class="bi bi-chevron-down"></i> -->
            <img *ngIf="moodFilters[1].checked" src="../assets/images/chevron-down.svg" />
          </div>
          <ng-container *ngIf="moodFilters[1].checked">
            <div class="range-slider-container">
              <div class="range-slider-labels">
                <span>Very Low</span>
                <span>Very High</span>
              </div>
              <ng5-slider [(value)]="moodFilters[1].range.value" [(highValue)]="moodFilters[1].range.highValue"
                [options]="sliderOptions"></ng5-slider>
            </div>
          </ng-container>
          <!-- Disgust -->
          <div class="mood-filter">
            <div class="form-check">
              <input class="form-check-input" [checked]="moodFilters[2].checked" type="checkbox" name="mood"
                id="disgust" value="Disgust" (change)="handleMoodChange($event, 'disgust')" />
              <label class="form-check-label" for="disgust">Disgust</label>
            </div>
            <i *ngIf="!moodFilters[2].checked" class="bi bi-chevron-right"></i>
            <!-- <i *ngIf="moodFilters[2].checked" class="bi bi-chevron-down"></i> -->
            <img *ngIf="moodFilters[2].checked" src="../assets/images/chevron-down.svg" />
          </div>
          <ng-container *ngIf="moodFilters[2].checked">
            <div class="range-slider-container">
              <div class="range-slider-labels">
                <span>Very Low</span>
                <span>Very High</span>
              </div>
              <ng5-slider [(value)]="moodFilters[2].range.value" [(highValue)]="moodFilters[2].range.highValue"
                [options]="sliderOptions"></ng5-slider>
            </div>
          </ng-container>
          <!-- sadness -->
          <div class="mood-filter">
            <div class="form-check">
              <input class="form-check-input" [checked]="moodFilters[3].checked" type="checkbox" name="mood"
                id="sadness" value="Sadness" (change)="handleMoodChange($event, 'sadness')" />
              <label class="form-check-label" for="sadness">Sadness</label>
            </div>
            <i *ngIf="!moodFilters[3].checked" class="bi bi-chevron-right"></i>
            <!-- <i *ngIf="moodFilters[3].checked" class="bi bi-chevron-down"></i> -->
            <img *ngIf="moodFilters[3].checked" src="../assets/images/chevron-down.svg" />
          </div>
          <ng-container *ngIf="moodFilters[3].checked">
            <div class="range-slider-container">
              <div class="range-slider-labels">
                <span>Very Low</span>
                <span>Very High</span>
              </div>
              <ng5-slider [(value)]="moodFilters[3].range.value" [(highValue)]="moodFilters[3].range.highValue"
                [options]="sliderOptions"></ng5-slider>
            </div>
          </ng-container>
          <!-- Fear -->
          <div class="mood-filter">
            <div class="form-check">
              <input class="form-check-input" [checked]="moodFilters[4].checked" type="checkbox" name="mood" id="fear"
                value="Fear" (change)="handleMoodChange($event, 'fear')" />
              <label class="form-check-label" for="fear">Fear</label>
            </div>
            <i *ngIf="!moodFilters[4].checked" class="bi bi-chevron-right"></i>
            <!-- <i *ngIf="moodFilters[4].checked" class="bi bi-chevron-down"></i> -->
            <img *ngIf="moodFilters[4].checked" src="../assets/images/chevron-down.svg" />
          </div>
          <ng-container *ngIf="moodFilters[4].checked">
            <div class="range-slider-container">
              <div class="range-slider-labels">
                <span>Very Low</span>
                <span>Very High</span>
              </div>
              <ng5-slider [(value)]="moodFilters[4].range.value" [(highValue)]="moodFilters[4].range.highValue"
                [options]="sliderOptions"></ng5-slider>
            </div>
          </ng-container>
        </div>
        <hr />
        <div class="dropdown-footer">
          <button type="button" class="btn" style="font-size: 13px;font-style: normal;font-weight: 600;"
            (click)="resetFilters('mood')">
            Reset
          </button>
          <button class="primary footer-btn" (click)="searchWithFilter('mood')">
            Apply
          </button>
        </div>
      </div>
    </div>
    <!-- Keyword -->
    <div class="filter">
      <button class="filter-btn" [ngClass]="{'active': (isSelectedExcludeKeywords || isSelectedKeywords)}"
        (click)="showDropdownFilters('keyword')">
        Key Word
        <img
          [src]="(isSelectedExcludeKeywords || isSelectedKeywords) ? '../assets/images/white-chevron-down.svg' : '../assets/images/chevron-down.svg'" />
      </button>
      <div class="dropdown-container" *ngIf="dropdownFilter.showKeyWordDropdown">
        <div class="dropdown-header">
          <span>Key word</span>
          <button (click)="showDropdownFilters('keyword')" type="button" class="btn"
            style="font-size: 13px;font-style: normal;font-weight: 600;">
            Cancel
          </button>
        </div>
        <div class="dropdown-content">
          <div class="form-group">
            <label for="">
              <span class="label-main">Include key word</span> <br>
              <span class="label-hint">(Hint: press enter to apply)</span>
            </label>
            <div class="input-container">
              <input type="text" class="form-control" [(ngModel)]="includeKeyword" (keydown)="onKeydownInclude($event)"
                placeholder="Enter a key word" />
              <div class="auth-icons">
                <!-- <button class="search-clear-button" *ngIf="includeKeyword">
                  ADD
                </button> -->
                <i class="bi bi-plus-circle-fill" style="margin-left: 10px;" *ngIf="includeKeyword"
                  (click)="onIncludeAdd()"></i>
              </div>
            </div>
            <div class="keyword-list">
              <div *ngFor="let keyword of includeKeywords" class="keyword-item">
                {{ keyword }}
                <span (click)="removeKeyword(keyword)" class="remove-keyword">&times;</span>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="">
              <span class="label-main">Exclude key word</span> <br>
              <span class="label-hint">(Hint: press enter to apply)</span>
            </label>
            <div class="input-container">
              <input type="text" class="form-control" [(ngModel)]="excludeKeyword" (keydown)="onKeydownExclude($event)"
                placeholder="Enter a key word" />
              <div class="auth-icons">
                <!-- <button class="search-clear-button" *ngIf="includeKeyword">
                                  ADD
                                </button> -->
                <i class="bi bi-plus-circle-fill" style="margin-left: 10px;" *ngIf="excludeKeyword"
                  (click)="onExcludeAdd()"></i>
              </div>
            </div>
            <div class="keyword-list">
              <div *ngFor="let keyword of excludeKeywords" class="keyword-item">
                {{ keyword }}
                <span (click)="removeKeywordExlude(keyword)" class="remove-keyword">&times;</span>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="dropdown-footer">
          <button type="button" class="btn" style="font-size: 13px;font-style: normal;font-weight: 600;"
            (click)="resetFilters('keyword')">
            Reset
          </button>
          <button class="primary footer-btn" (click)="searchWithFilter('keyword')">Apply</button>
        </div>
      </div>
    </div>
    <!-- Location -->
    <div class="filter">
      <button class="filter-btn" [ngClass]="{'active': isSelectedCountry || isSelectedCity}"
        (click)="showDropdownFilters('location')">
        Location
        <img
          [src]="(isSelectedCountry || isSelectedCity) ? '../assets/images/white-chevron-down.svg' : '../assets/images/chevron-down.svg'" />
      </button>

      <div class="dropdown-container" *ngIf="dropdownFilter.showLocationDropdown">
        <div class="dropdown-header">
          <span>Location</span>
          <button (click)="showDropdownFilters('location')" type="button" class="btn"
            style="font-size: 13px;font-style: normal;font-weight: 600;">
            Cancel
          </button>
        </div>

        <div class="dropdown-content">
          <div *ngFor="let loc of locations" class="dropdown-title">
            <input type="radio" [checked]="selectedCountry === loc.value" (change)="toggleLocation(loc)"
              style="background-color: black;" />
            <label class="txt"> {{ loc.label }} </label>
          </div>
        </div>
        <hr />
        <div>
          <div class="footer-content">
            <div class="input-container" style="padding: 0px 10px;">
              <input type="text" class="form-control" [(ngModel)]="citySearch" placeholder="Search city" />
              <div class="auth-icons-text">
                <button class="search-clear-button" *ngIf="citySearch" (click)="onSearchCity()">
                  Add
                </button>
                <!-- <i class="bi bi-x-circle-fill" style="margin-left: 10px;" *ngIf="citySearch"></i> -->
              </div>
              <!-- <div class="auth-icons">
                <i class="bi bi-x-circle-fill" *ngIf="citySearch" (click)="onCityClear()"></i>
              </div> -->
            </div>
            <div *ngIf="cityKeyword" style="padding: 0px 10px;">
              <span class="language-title"> {{cityKeyword}} </span>
              <i class="bi bi-x-lg" (click)="onCityClear()"></i>
            </div>
            <div class="custom-divider"></div>
            <div class="dropdown-footer">
              <button type="button" class="btn"
                style="font-size: 13px;font-style: normal;font-weight: 600;">Reset</button>
              <button class="primary footer-btn" (click)="searchWithFilter('location')">Apply</button>
            </div>
          </div>


        </div>
      </div>
    </div>
    <!-- Language -->
    <div class="filter">
      <button class="filter-btn" [ngClass]="{'active': isSelectedLan}" (click)="showDropdownFilters('language')">
        Language
        <!-- <i class="bi bi-chevron-down"></i> -->
        <img
          [src]="(isSelectedLan) ? '../assets/images/white-chevron-down.svg' : '../assets/images/chevron-down.svg'" />
      </button>

      <div class="dropdown-container" *ngIf="dropdownFilter.showLanguageDropdown">
        <div class="dropdown-header">
          <span>Language</span>
          <button (click)="showDropdownFilters('language')" type="button" class="btn"
            style="font-size: 13px;font-style: normal;font-weight: 600;">
            Cancel
          </button>
        </div>
        <div class="dropdown-content">
          <div *ngFor="let language of languages" class="dropdown-title">
            <input type="checkbox" [checked]="selectedLanguages.includes(language)"
              (change)="toggleLanguage(language)" />
            <label class="txt"> {{ language.label }} </label>
          </div>
        </div>
        <hr />
        <div class="dropdown-footer">
          <button class="primary footer-btn" (click)="searchWithFilter('language')">
            Apply
          </button>
        </div>
      </div>
    </div>
    <!-- Time frame -->
    <div class="filter">
      <button class="filter-btn" [ngClass]="{'active': isTimeFrameSelected}" (click)="showDropdownFilters('timeFrame')">
        Time Frame
        <img
          [src]="(isTimeFrameSelected) ? '../assets/images/white-chevron-down.svg' : '../assets/images/chevron-down.svg'" />
      </button>

      <div class="dropdown-container" *ngIf="dropdownFilter.showTimeframeDropdown">
        <div class="dropdown-header">
          <span>Timeframe</span>
          <button (click)="showDropdownFilters('timeFrame')" type="button" class="btn"
            style="font-size: 13px;font-style: normal;font-weight: 600;">
            Cancel
          </button>
        </div>
        <div class="dropdown-content">
          <div class="row">
            <div class="col">
              <label for="startDate">Start date</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" style="background-color: #E8E8E8;">
                    <i class="bi bi-calendar" style="background-color: #E8E8E8;"></i>
                  </span>
                </div>
                <input type="text" class="form-control" maxlength="4" placeholder="YYYY" id="startDate"
                  [(ngModel)]="startDate" style="background-color: #E8E8E8;" />
              </div>
            </div>
            <div class="col">
              <label for="endDate">End date</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" style="background-color: #E8E8E8;">
                    <i class="bi bi-calendar" style="background-color: #E8E8E8;"></i>
                  </span>
                </div>
                <input type="text" class="form-control" maxlength="4" placeholder="YYYY" id="endDate"
                  style="background-color: #E8E8E8;" [(ngModel)]="endDate" />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="dropdown-footer">
          <button type="button" class="btn" (click)="resetFilters('timeframe')"
            style="font-size: 13px;font-style: normal;font-weight: 600;">
            Reset
          </button>
          <button class="primary footer-btn" (click)="searchWithFilter('timeFrame')">
            Apply
          </button>
        </div>
      </div>
    </div>
    <!-- change display -->
    <div class="toggle-container">
      <div class="toggle-button list-view" [class.active]="displayStyle == 'list'" (click)="changeDisplayStyle('list')">
        <i class="bi bi-list-task"></i>
        <span>List</span>
      </div>
      <div class="toggle-button card-view" [class.active]="displayStyle == 'cards'"
        (click)="changeDisplayStyle('cards')">
        <i class="bi bi-ui-checks-grid"></i>
        <span>Cards</span>
      </div>
    </div>
  </div>

  <!-- Total count text -->
  <div class="title-row">
    <div class="title" *ngIf="!isLoading">
      {{ searchResults['data']?.hits.length }} Results
    </div>
    <button class="btn btn-light" *ngIf="showReset && !isLoading"
      style=" align-self: self-end; background-color: #E8E8E8; border-radius: 15px; padding: 5px 20px; margin-left: 10px;"
      (click)="onReset()">
      Reset
    </button>
  </div>

  <div class="applied-filters">
    <div *ngFor="let mood of selectedMoods let i = index">
      <div class="language-row">
        Mood :
        <span class="language-title"> {{mood.name }} </span>
        <i class="bi bi-x-lg" (click)="onSelectedMoodClear(i)"></i>
      </div>
    </div>
    <div class="language-row" *ngIf="startDate && isTimeFrameSelected">
      From :
      <span class="language-title"> {{startDate}} - {{endDate}} </span>
      <i class="bi bi-x-lg" (click)="onSelectedTimeClear()"></i>
    </div>
    <div class="language-row" *ngIf="selectedCountry && isSelectedCountry">
      Location :
      <span class="language-title"> {{ selectedCountry }} </span>
      <i class="bi bi-x-lg" (click)="onSelectedLocationClear()"></i>
    </div>
    <div class="language-row" *ngIf=" cityKeyword && isSelectedCity">
      City :
      <span class="language-title"> {{ cityKeyword }} </span>
      <i class="bi bi-x-lg" (click)="onSelectedCityClear()"></i>
    </div>
  </div>

  <div class="applied-filters">
    <ng-container *ngIf="isSelectedKeywords">
      <div *ngFor="let include of includeKeywords let i = index">
        <div class="language-row">
          Include :
          <span class="language-title"> {{include}} </span>
          <i class="bi bi-x-lg" (click)="onSelectedIncludeClear(i)"></i>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="isSelectedExcludeKeywords">
      <div *ngFor="let exclude of excludeKeywords let i = index">
        <div class="language-row">
          Exclude :
          <span class="language-title"> {{exclude}} </span>
          <i class="bi bi-x-lg" (click)="onSelectedExcludeClear(i)"></i>
        </div>
      </div>
    </ng-container>

  </div>


  <div class="applied-filters" *ngIf="isSelectedLan">
    <div *ngFor="let lan of selectedLanguages let i = index">
      <div class="language-row">
        Language :
        <span class="language-title"> {{lan.label }} </span>
        <i class="bi bi-x-lg" (click)="onSelectedLanClear(i)"></i>
      </div>
    </div>
    <!-- <div class="language-row" *ngIf="selectedLanguages.length >=2 && isSelectedLan">
      Language :
      <span class="language-title"> {{selectedLanguages[0]}} ({{selectedLanguages.length - 1}})</span>
      <i class="bi bi-x-lg" (click)="onSelectedLanClear()"></i>
    </div>
    <div class="language-row" *ngIf="selectedLanguages.length ==1 && isSelectedLan">
      Language :
      <span class="language-title"> {{selectedLanguages[0] }} </span>
      <i class="bi bi-x-lg" (click)="onSelectedLanClear()"></i>
    </div> -->
    <!-- <div class="language-row" *ngIf="selectedMoods.length >=2">
      Mood :
      <span class="language-title"> {{selectedMoods[0].name}} ({{selectedMoods.length - 1}})</span>
      <i class="bi bi-x-lg" (click)="onSelectedMoodClear()"></i>
    </div>
    <div class="language-row" *ngIf="selectedMoods.length ==1">
      Mood :
      <span class="language-title"> {{selectedMoods[0].name }} </span>
      <i class="bi bi-x-lg" (click)="onSelectedMoodClear()"></i>
    </div> -->
    <!-- <div class="language-row" *ngIf="startDate && isTimeFrameSelected">
      From :
      <span class="language-title"> {{startDate}} - {{endDate}} </span>
      <i class="bi bi-x-lg" (click)="onSelectedTimeClear()"></i>
    </div>
    <div class="language-row" *ngIf="selectedCountry && isSelectedCountry">
      Location :
      <span class="language-title"> {{ selectedCountry }} </span>
      <i class="bi bi-x-lg" (click)="onSelectedLocationClear()"></i>
    </div> -->
    <!-- <div class="language-row" *ngIf="includeKeywords.length >=2 && isSelectedKeywords">
      Include :
      <span class="language-title"> {{includeKeywords[0]}} ({{includeKeywords.length - 1}})</span>
      <i class="bi bi-x-lg" (click)="onSelectedIncludeClear()"></i>
    </div>
    <div class="language-row" *ngIf="includeKeywords.length ==1 && isSelectedKeywords">
      Include :
      <span class="language-title"> {{includeKeywords[0] }} </span>
      <i class="bi bi-x-lg" (click)="onSelectedIncludeClear()"></i>
    </div>
    <div class="language-row" *ngIf="excludeKeywords.length >=2 && isSelectedExcludeKeywords">
      Exclude :
      <span class="language-title"> {{excludeKeywords[0]}} ({{excludeKeywords.length - 1}})</span>
      <i class="bi bi-x-lg" (click)="onSelectedExcludeClear()"></i>
    </div>
    <div class="language-row" *ngIf="excludeKeywords.length ==1 && isSelectedExcludeKeywords">
      Exclude :
      <span class="language-title"> {{excludeKeywords[0] }} </span>
      <i class="bi bi-x-lg" (click)="onSelectedExcludeClear()"></i>
    </div> -->
    <!-- <button class="btn btn-light"
      style=" align-self: self-end; background-color: #E8E8E8; border-radius: 15px; padding: 5px 20px; margin-left: 10px;"
      *ngIf="showReset" (click)="onReset()">
      Reset
    </button> -->
  </div>
  <button class="btn btn-light"
    style=" align-self: self-end; background-color: #000; border-radius: 15px; padding: 5px 20px; margin-left: 10px; color:white;"
    *ngIf="searchResults && searchResults['data']?.hits.length !== 0 && this.permissionsMap.ExportCSV === 1"
    (click)="exportCSV()">
    Export CSV
  </button>
  <!-- tabs -->
  <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
    <!-- lyrics -->
    <li [ngbNavItem]="1">
      <button class="nav-link-btn" ngbNavLink>
        <i class="bi bi-chat-left-text"></i>
        <span>Lyrics</span>
      </button>
      <ng-template ngbNavContent>
        <ng-container *ngIf="displayStyle == 'cards'; else dataInListStyle">
          <div class="cards" *ngIf="
              searchResults && searchResults['data']?.hits.length !== 0;
              else noSongsFound
            ">
            <div class="card" *ngFor="let song of paginatedSearchResults let i = index">
              <div class="lyrics">
                <div class="text-limit"> {{song.lyrics}}</div>
                <!-- <span style="color: green">rhyme</span> -->
                <!-- <div class="play-button">

                  <img src="../assets/images/bookmark.svg" />
                </div> -->
              </div>
              <div class="song-details">
                <div class="information">
                  <div class="song-title">{{song.title}}</div>
                  <div class="artist">{{song.artist_name}}</div>
                  <div class="album">{{song.artist.city}} {{song.artist.state}} {{song.artist.country}}</div>
                </div>
                <div class="controls">
                  <div class="play-button">
                    <img src="../assets/images/play.svg" (click)="onPlayYouTube(song)" style="cursor: pointer;" />
                  </div>

                  <div class="dropdown-share">
                    <!-- <button type="button" class="btn btn-light view-queries-btn" (click)="onShare(i)">
                      Share
                      <i class="bi bi-share-fill"></i>
                    </button> -->
                    <div class="more-options" (click)="onShareGrid(i)">
                      <img src="../assets/images/dots-vertical.svg" style="cursor: pointer;" />
                    </div>
                    <div class="dropdown-content-share" [class.show]="activeDropdownGrid === i">
                      <button (click)="copyLink(song)">
                        <i class="bi bi-copy"></i>
                        Copy Song Link</button>
                      <button (click)="openGoogle(song)">
                        <i class="bi bi-search"></i>
                        Search lyrics online</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="pagination" *ngIf="searchResults && searchResults['data']?.hits.length > 0">
            <rap-pagination [collectionSize]="searchResults['data']?.hits.length" [page]="page" [pageSize]="pageSize"
              (onPageChange)="onPageChange($event)"></rap-pagination>
          </div>
        </ng-container>
      </ng-template>
    </li>
    <!-- Map -->
    <!-- <li [ngbNavItem]="2">
      <button class="nav-link-btn" ngbNavLink>
        <i class="bi bi-map"></i>
        <span>Map</span>
      </button>
      <ng-template ngbNavContent>
        <div>
          <canvasjs-chart [options]="chartOptions" [styles]="{ width: '100%', height: '360px' }"></canvasjs-chart>
        </div>
      </ng-template>
    </li> -->
    <!-- Rankings -->
    <!-- <li [ngbNavItem]="3">
      <button class="nav-link-btn" ngbNavLink>
        <i class="bi bi-lightning-charge"></i>
        <span>Rankings</span>
      </button>
      <ng-template ngbNavContent>
        <p>
          Sed commodo, leo at suscipit dictum, quam est porttitor sapien, eget
          sodales nibh elit id diam. Nulla facilisi. Donec egestas ligula vitae
          odio interdum aliquet. Duis lectus turpis, luctus eget tincidunt eu,
          congue et odio. Duis pharetra et nisl at faucibus. Quisque luctus
          pulvinar arcu, et molestie lectus ultrices et. Sed diam urna, egestas
          ut ipsum vel, volutpat volutpat neque. Praesent fringilla tortor arcu.
          Vivamus faucibus nisl enim, nec tristique ipsum euismod facilisis.
          Morbi ut bibendum est, eu tincidunt odio. Orci varius natoque
          penatibus et magnis dis parturient montes, nascetur ridiculus mus.
          Mauris aliquet odio ac lorem aliquet ultricies in eget neque.
          Phasellus nec tortor vel tellus pulvinar feugiat.
        </p>
      </ng-template>
    </li> -->
    <!-- Word cloud -->
    <!-- <li [ngbNavItem]="4">
      <button class="nav-link-btn" ngbNavLink>
        <i class="bi bi-cloud-lightning"></i>
        <span>Word Cloud</span>
      </button>
      <ng-template ngbNavContent>
        <p>
          Sed commodo, leo at suscipit dictum, quam est porttitor sapien, eget
          sodales nibh elit id diam. Nulla facilisi. Donec egestas ligula vitae
          odio interdum aliquet. Duis lectus turpis, luctus eget tincidunt eu,
          congue et odio. Duis pharetra et nisl at faucibus. Quisque luctus
          pulvinar arcu, et molestie lectus ultrices et. Sed diam urna, egestas
          ut ipsum vel, volutpat volutpat neque. Praesent fringilla tortor arcu.
          Vivamus faucibus nisl enim, nec tristique ipsum euismod facilisis.
          Morbi ut bibendum est, eu tincidunt odio. Orci varius natoque
          penatibus et magnis dis parturient montes, nascetur ridiculus mus.
          Mauris aliquet odio ac lorem aliquet ultricies in eget neque.
          Phasellus nec tortor vel tellus pulvinar feugiat.
        </p>
      </ng-template>
    </li> -->
  </ul>

  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>

<ng-template #dataInListStyle>
  <!-- <div class="custom-container"> -->
  <div>
    <table class="table rounded-4 overflow-hidden" *ngIf="
      searchResults && searchResults['data']?.hits.length > 0;
      else noSongsFound
    ">
      <thead>
        <tr>
          <th scope="col">Title</th>
          <th scope="col">Album</th>
          <th scope="col">Lyrics</th>
          <th scope="col">Release</th>
          <th scope="col">Created at</th>
          <th scope="col">Share</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let song of paginatedSearchResults let i = index">
          <td scope="row">{{ song.title }}</td>
          <td>{{ song.album.album }}</td>
          <td>
            <div class="text-limit">
              {{ song.lyrics }}
            </div>

          </td>

          <td>{{ song.album.release_date | date: 'yyyy' }}</td>
          <td>{{song.artist.city}} {{song.artist.state}} {{song.artist.country}}</td>

          <td>
            <div class="dropdown-share">
              <button type="button" class="btn btn-light view-queries-btn" (click)="onShare(i)">
                Share
                <i class="bi bi-share-fill"></i>
              </button>
              <div class="dropdown-content-share" [class.show]="activeDropdown === i">
                <button (click)="copyLink(song)">
                  <i class="bi bi-copy"></i>
                  Copy Song Link</button>
              </div>
            </div>

          </td>
        </tr>
        <tr *ngIf="searchResults.length === 0">
          <td colspan="4" style="text-align: center">No songs found</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="pagination" *ngIf="searchResults && searchResults['data']?.hits.length > 0">
    <rap-pagination [collectionSize]="searchResults['data']?.hits.length" [page]="page" [pageSize]="pageSize"
      (onPageChange)="onPageChange($event)"></rap-pagination>
  </div>
</ng-template>

<ng-template #noSongsFound>
  <div style="padding: 20px; text-align: center; margin-top: 100px;" *ngIf="!isLoading">
    <img src="../assets/images/question.svg" />
    <div class="no-match" *ngIf="searchTerm !== ''"> We didn’t find a match for {{searchTerm}}</div>
    <div class="no-match" *ngIf="searchTerm === ''"> Search by song or album title, lyrics or artist name</div>
    <div *ngIf="showReset">Try searching for something else instead or remove some filters.</div>
  </div>
</ng-template>