import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environments';
import { Roles } from '../models/roles/roles.model';
import { Song } from '../models/songs/song.model';
import { DropdownValues } from '../users/store/users.state';
import { CreateSongRequest } from '../models/songs/create-song-request.model';

@Injectable({
  providedIn: 'root',
})
export class SongsService {
  private readonly BASE_URL = 'baseUrl';
  private url: string;

  constructor(private httpClient: HttpClient) {
    this.url = environment.baseUrl;
  }

  getAllSongs() {
    return this.httpClient.get<any>(this.url + `v2/songs`);
  }

  getMySongs() {
    return this.httpClient.get<any>(this.url + `v2/songs/my-songs`);
  }

  getSongById(songId: string) {
    return this.httpClient.get<Song>(this.url + `v2/songs/` + songId);
  }

  addNewSong(newSong: CreateSongRequest) {
    return this.httpClient.post(this.url + `v2/songs/new`, newSong);
  }

  //   updateRole(updatedRole: UpdateRoleRequest) {
  //     return this.httpClient.put(
  //       this.url + `v2/roles/` + updatedRole.id,
  //       updatedRole,
  //     );
  //   }

  getAlbums() {
    return this.httpClient.get<DropdownValues[]>(
      this.url + `v2/albums/autocomplete?query=&limit=100`,
    );
  }

  getArtists() {
    return this.httpClient.get<DropdownValues[]>(
      this.url + `v2/artists/autocomplete?query=&limit=100`,
    );
  }

  getProducers() {
    return this.httpClient.get<DropdownValues[]>(
      this.url + `v2/producers/autocomplete?query=&limit=100`,
    );
  }

  deleteSong(songId: string) {
    return this.httpClient.delete(this.url + `v2/songs/` + songId);
  }
}
