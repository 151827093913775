<div class="label-container">
    <div>
        <label class="clickable-label" routerLink="/dashboard/explore"> Rap Almanac </label>
        <img src="../assets/images/filled.svg" />
    </div>
    <div>
        <label class="non-clickable-label">App</label>
        <img src="../assets/images/filled.svg" />
    </div>
    <div>
        <label class="clickable-label" routerLink="/dashboard/cards">Card</label>
        <!-- <img src="../assets/images/filled.svg" /> -->
    </div>

    <!-- <label class="clickable-label" routerLink="/dashboard/roles">Roles</label> -->
    <div *ngIf="id !== null">
        <img src="../assets/images/filled.svg" />
        <label class="non-clickable-label">{{ id }}</label>
    </div>
    <div>
        <img src="../assets/images/filled.svg" />
        <label class="non-clickable-label">Edit</label>
    </div>
</div>
<div class="functions-section">
    <div class="section-title">
        Edit Card
    </div>
    <rap-spinner class="loader-overlay" *ngIf="isLoading"></rap-spinner>
    <div class="card" *ngIf="cardItem !== null">
        <div class="card-body" style="max-width: 50%;">
            <div class="col">
                Image
            </div>
            <div class="col" style=" padding: 10px; margin-bottom: 10px;">
                <button type="button" class="btn btn-light action-button" (click)="onUploadImage()">
                    Upload Image
                </button>
            </div>
            <div class="col" style=" padding: 10px; margin-bottom: 10px;">
                Chart Preview
            </div>
            <div *ngIf="cardItem?.chartType !== null">
                <div *ngIf="cardItem.chartType === 'Bar chart'">
                    <canvasjs-chart [options]="getChartOptions(cardItem)"
                        [styles]="{ width: '100%', height:'300px'}"></canvasjs-chart>
                </div>
                <div *ngIf="cardItem.chartType === 'Timeline'">
                    <canvasjs-chart [options]="getChartOptionsTimeLine(cardItem)"
                        [styles]="{ width: '80%', height:'300px'}"></canvasjs-chart>
                </div>
            </div>
            <div class="role-action-btns">
                <button type="submit" class="btn btn-dark" style="padding: 5px 50px;" (click)="onSave()">Save</button>
                <button type="button" class="btn btn-light action-button" (click)="onReset()">
                    Reset
                </button>
                <button type="button" class="btn btn-light action-button" (click)="onCancel()">
                    Cancel
                </button>
            </div>
        </div>
    </div>
</div>