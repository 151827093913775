import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private router: Router) { }

  storeAccessToken(token: string) {
    localStorage.setItem('accessToken', token);
  }

  storeUserId(id: string) {
    localStorage.setItem('id', id);
  }

  storeUserEmail(email: string) {
    localStorage.setItem('email', email);
  }

  storeUserName(name: string) {
    localStorage.setItem('name', name);
  }

  storeUserAvatar(avatar: string) {
    localStorage.setItem('avatar', avatar);
  }

  storeUserPermissions(permissions: string) {
    localStorage.setItem('permissions', permissions);
  }

  getAccessToken(): string | null {
    return localStorage.getItem('accessToken');
  }

  getUserId(): string | null {
    return localStorage.getItem('id');
  }

  getUserName(): string | null {
    return localStorage.getItem('name');
  }

  getUserAvatar(): string | null {
    return localStorage.getItem('avatar');
  }

  getUserEmail(): string | null {
    return localStorage.getItem('email');
  }

  getUserPermissions(): string | null {
    return localStorage.getItem('permissions');
  }

  isLoggedIn() {
    return !!localStorage.getItem('accessToken');
  }

  logout() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('id');
    localStorage.removeItem('name');
    localStorage.removeItem('avatar');
    localStorage.removeItem('email');
    localStorage.removeItem('permissions');
    this.router.navigate(['/signin']);
  }
}
