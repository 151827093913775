import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Roles } from '../models/roles/roles.model';
import { DeleteModalComponent } from '../shared/component/delete-modal/delete-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseTemplateService } from '../base-template/base-template.service';

@Component({
  selector: 'rap-roles-ui',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss'],
})
export class RolesComponent {
  @Input() roles: Roles[];
  @Output() createRoleEmitter = new EventEmitter<void>();
  @Output() editRoleEmitter = new EventEmitter<Roles>();
  @Output() deleteRoleEmitter = new EventEmitter<string>();
  page = 1;
  pageSize = 10;
  isLoading = false;
  searchQuery = "";
  filters: any = {
    search: ""
  };
  rolesData: Roles[] = [];
  constructor(private modalService: NgbModal, private baseTemplateService: BaseTemplateService) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.roles = [];

    // this.baseTemplateService.search$.subscribe(searchTerm => {
    //   this.filters.search = searchTerm;
    //   if (this.roles.length > 0)
    //     this.getSearchResults();
    // });

    this.baseTemplateService.showHideSearch(false);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['roles'] && this.roles) {
      this.isLoading = false;
      this.page = 1;
      this.rolesData = this.roles;
    }
  }

  get paginatedRoles(): Roles[] {
    const start = (this.page - 1) * this.pageSize;
    const end = start + this.pageSize;
    return this.roles.slice(start, end);
  }

  onPageChange(page: number) {
    this.page = page;
  }

  deleteRole(roleId: string) {

    const modalRef = this.modalService.open(DeleteModalComponent);
    modalRef.componentInstance.itemId = roleId;
    modalRef.result
      .then((id) => {
        this.isLoading = true;
        this.deleteRoleEmitter.emit(id);
      })
      .catch((error) => {
        this.isLoading = false;
      });
  }

  editRole(role: Roles) {
    this.editRoleEmitter.emit(role);
  }

  getSearchResults() {
    if (this.filters.search) {
      this.searchQuery = this.filters.search;
      var localArr = this.roles.filter(item =>
        item.name?.toLowerCase().includes(this.filters.search.toLowerCase())
      );
      this.roles = localArr;
    } else {
      this.roles = this.rolesData;
    }
  }

  onRoles() {
    //  window.open('https://www.rapalmanac.com/privacy-notice', '_blank');
  }

  onRapAlmanc() {

  }

  ngOnDestroy(): void {
    this.roles = [];
    this.rolesData = [];
    this.filters.search = "";
  }
}
