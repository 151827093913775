import { Component, EventEmitter, Input, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { CreateUserRequest } from 'src/app/models/users/create-user-request.model';
import { UpdateUserRequest } from 'src/app/models/users/update-user-request.model';
import { User } from 'src/app/models/users/user.model';
import { DropdownValues } from '../store/users.state';

@Component({
  selector: 'rap-add-edit-user-ui',
  templateUrl: './add-edit-user.component.html',
  styleUrls: ['./add-edit-user.component.scss']
})
export class AddEditUserComponent {
  @Input() title: string;
  @Input() editableUser: User;
  @Input() roles: DropdownValues[];
  @Input() rosters: DropdownValues[];
  @Input() errorMessage: string;
  @Output() createNewUserEmitter = new EventEmitter<CreateUserRequest>();
  @Output() updateUserEmitter = new EventEmitter<UpdateUserRequest>();

  userForm: FormGroup;
  submitted: boolean = false;
  constructor(private formBuilder: FormBuilder) {
    this.userForm = this.formBuilder.group({
      firstName: new FormControl(null, [Validators.required]),
      lastName: new FormControl(null, [Validators.required]),
      userName: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [Validators.required]),
      role: new FormControl(null, [Validators.required]),
      studentRoster: new FormControl(null, [Validators.required]),
      active: new FormControl(false, [Validators.required]),
      allowMapperDelight: new FormControl(false, [Validators.required]),
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['editableUser']?.currentValue) {
      this.userForm.patchValue({
        firstName: this.editableUser.firstName,
        lastName: this.editableUser.lastName,
        userName: this.editableUser.userName,
        email: this.editableUser.email,
        role: this.editableUser.roleId,
        studentRoster: this.editableUser.studentRosterId,
        active: this.editableUser.active,
        allowMapperDelight: this.editableUser.allowMD,
      });
    }
  }

  get f(): { [key: string]: AbstractControl } {
    return this.userForm.controls;
  }

  addUpdateUser() {
    this.submitted = true;
    if (this.userForm.valid) {
      let userRequest: CreateUserRequest = {
        data: {
          firstName: this.userForm.value.firstName,
          lastName: this.userForm.value.lastName,
          userName: this.userForm.value.userName,
          email: this.userForm.value.email,
          roleId: this.userForm.value.role,
          studentRosterId: this.userForm.value.studentRoster,
          active: this.userForm.value.active,
          role: this.userForm.value.role,
          student_roster: this.userForm.value.studentRoster,
        },
      };

      if (this.editableUser) {
        let userRequest: UpdateUserRequest = {
          id: this.editableUser.id,
          data: {
            firstName: this.userForm.value.firstName,
            lastName: this.userForm.value.lastName,
            userName: this.userForm.value.userName,
            email: this.userForm.value.email,
            roleId: this.userForm.value.role,
            studentRosterId: this.userForm.value.studentRoster,
            active: this.userForm.value.active,
            allowMD: this.userForm.value.allowMapperDelight,
          },
        };
        this.updateUserEmitter.emit(userRequest);
      } else {
        this.createNewUserEmitter.emit(userRequest);
      }
    }
  }

  ngOnDestroy() {
    this.userForm.reset();
  }
}
