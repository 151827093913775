<div class="label-container">
    <div>
        <label class="clickable-label" routerLink="/dashboard/explore"> Rap Almanac </label>
        <img src="../assets/images/filled.svg" />
    </div>

    <div>
        <label class="non-clickable-label">App</label>
        <img src="../assets/images/filled.svg" />
    </div>


    <label class="non-clickable-label">{{title}}</label>

    <div>
        <img src="../assets/images/filled.svg" />
        <label class="non-clickable-label">Artists</label>
    </div>


</div>

<div class="songs-section">
    <div class="section-title">Artists</div>
    <button type="button" class="btn btn-dark add-song-btn" (click)="createArtist()" style="margin-bottom: 20px;">
        Add New
    </button>
    <rap-spinner class="loader-overlay" *ngIf="isLoading"></rap-spinner>
    <div class="search-container">
        <img src="../assets/images/search.svg" class="search-icon" />
        <input type="text" placeholder="Search..." class="search-input" [(ngModel)]="filters.search"
            (keydown.enter)="getSearchResults()">
    </div>
    <ng-container *ngIf="artistsData?.length > 0">
        <div class="custom-container">
            <div class="songs-table" *ngIf="artistsData?.length > 0">
                <table class="table rounded-4 overflow-hidden">
                    <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">City</th>
                            <th scope="col">State</th>
                            <th scope="col">Country</th>
                            <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let artist of paginatedArtists">
                            <td scope="row">{{ artist?.artist }}</td>
                            <td scope="row">
                                {{ artist?.city }}
                            </td>
                            <td scope="row">{{ artist?.state }}</td>
                            <td scope="row">{{ artist?.country }}</td>
                            <td>
                                <button type="button" class="btn btn-light edit-song-btn" (click)="editArtist(artist)">
                                    Edit
                                </button>
                                <button type="button" class="btn btn-dark delete-song-btn"
                                    (click)="deleteArtist(artist.artistid)">
                                    Delete
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div class="pagination">
                    <rap-pagination [collectionSize]="pagesCount" [page]="page" [pageSize]="pageSize"
                        (onPageChange)="onPageChange($event)"></rap-pagination>
                </div>
            </div>
        </div>

    </ng-container>


</div>

<div style="padding: 20px; text-align: center; margin-top: 100px;" *ngIf="artistsData?.length === 0 && !isLoading">
    <img src="../assets/images/question.svg" />
    <div class="no-match">No Artists found</div>
</div>