import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environments';
import { User } from '../models/users/user.model';
import { CreateUserRequest } from '../models/users/create-user-request.model';
import { UpdateUserRequest } from '../models/users/update-user-request.model';
import { BehaviorSubject } from 'rxjs';
import { UserData } from '../models/user-data.model';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private readonly BASE_URL = 'baseUrl';
  private url: string;

  constructor(private httpClient: HttpClient) {
    this.url = environment.baseUrl;
  }

  getUsers(page: number) {
    return this.httpClient.get<any>(this.url + `v2/users?limit=10&page=${page}`);
  }

  getUserById(id: string) {
    return this.httpClient.get<User>(this.url + `v2/users/` + id);
  }

  createNewUser(user: CreateUserRequest) {
    return this.httpClient.post(this.url + `v2/users`, user, {
      responseType: 'text',
    });
  }

  updateUser(user: UpdateUserRequest) {
    return this.httpClient.put(this.url + `v2/users/` + user.id, user, {
      responseType: 'text',
    });
  }

  deleteUser(userId: string) {
    return this.httpClient.delete(this.url + `v2/users/` + userId, {
      responseType: 'text',
    });
  }

  approveUser(id: string) {
    return this.httpClient.put(this.url + `v2/users/approve/` + id, null);
  }

  performAction(action: any) {
    return this.httpClient.post(this.url + `v2/users/actions`, action, {
      responseType: 'text',
    });
  }

  getRosters() {
    return this.httpClient.get<{ id: string; label: string }[]>(
      this.url + `v2/rosters/autocomplete?query=&limit=100`,
    );
  }

  getRoles() {
    return this.httpClient.get<{ id: string; label: string }[]>(
      this.url + `v2/roles/autocomplete?query=&limit=100`,
    );
  }
}
