import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SongsFacade } from '../store/songs.facade';

@Component({
  selector: 'rap-add-edit-song',
  templateUrl: './add-edit-song.container.component.html',
})
export class AddEditSongContainerComponent {
  albums$ = this.songsFacade.albums$;
  artists$ = this.songsFacade.artists$;
  producers$ = this.songsFacade.producers$;
  errorMessage$ = this.songsFacade.errorMessage$;
  title = 'Add Song';
  constructor(
    private songsFacade: SongsFacade,
    private route: ActivatedRoute,
  ) {
    this.songsFacade.getAlbums();
    this.songsFacade.getArtists();
    this.songsFacade.getProducers();
    this.route.params.subscribe((params) => {
      if (params['id']) {
        this.title = 'Edit Song';
        this.songsFacade.getSongById(params['id']);
      }
    });
  }

  ngOnDestroy() {
    this.songsFacade.clearErrorMessage();
  }

  addSong(song) {
    this.songsFacade.addSong(song);
  }

  editSong(song) {
    // this.songsFacade.updateSong(song);
  }
}
