<div class="functions-section">
  <div class="section-title">Functions</div>
  <button type="button" class="btn btn-dark add-function-btn" routerLink="/dashboard/functions/add"
    style="margin-bottom: 20px;">
    Add new
  </button>
  <rap-spinner class="loader-overlay" *ngIf="isLoading"></rap-spinner>
  <ng-container *ngIf="functions?.length > 0">
    <div class="custom-container">
      <div class="functions-table" *ngIf="functions?.length > 0">
        <table class="table rounded-4 overflow-hidden">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let function of paginatedFunctions">
              <td scope="row">{{ function?.name }}</td>
              <td>
                <button type="button" class="btn btn-light edit-function-btn" (click)="editFunction(function)">
                  Edit
                </button>
                <button type="button" class="btn btn-dark delete-function-btn" (click)="deleteFunction(function.id)">
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="pagination">
          <rap-pagination [collectionSize]="functions?.length" [page]="page" [pageSize]="pageSize"
            (onPageChange)="onPageChange($event)"></rap-pagination>
        </div>
      </div>
    </div>
  </ng-container>



  <!-- <div class="card" *ngIf="!functions || functions?.length === 0">
    <div class="card-body">
      <div class="no-functions-found">No functions found</div>
    </div>
  </div> -->
</div>

<div style="padding: 20px; text-align: center; margin-top: 100px;"
  *ngIf="(!functions || functions?.length === 0) && !isLoading">
  <img src="../assets/images/question.svg" />
  <div class="no-match" *ngIf="searchQuery !== ''"> We didn’t find a match for {{searchQuery}}</div>
  <div class="no-match" *ngIf="searchQuery === ''">No functions found </div>
</div>