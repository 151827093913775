<div class="songs-section">
    <div class="section-title">Songs</div>
    <button type="button" class="btn btn-dark add-song-btn" (click)="createSongEmitter.emit()"
        style="margin-bottom: 20px;">
        Add new
    </button>
    <rap-spinner class="loader-overlay" *ngIf="isLoading"></rap-spinner>
    <ng-container *ngIf="songs?.length > 0">
        <div class="custom-container">
            <div class="songs-table" *ngIf="songs?.length > 0">
                <table class="table rounded-4 overflow-hidden">
                    <thead>
                        <tr>
                            <th scope="col">Song title</th>
                            <th scope="col">Artist</th>
                            <th scope="col">Album</th>
                            <th scope="col">Tags</th>
                            <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let song of paginatedSongs">
                            <td scope="row">{{ song?.title }}</td>
                            <td scope="row">
                                {{ song?.artist.artist }}
                            </td>
                            <td scope="row">{{ song?.album.album }}</td>
                            <td scope="row">{{ song?.tags }}</td>
                            <td>
                                <button type="button" class="btn btn-light edit-song-btn" (click)="editSong(song)">
                                    Edit
                                </button>
                                <button type="button" class="btn btn-dark delete-song-btn"
                                    (click)="deleteSong(song.songid)">
                                    Delete
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div class="pagination">
                    <rap-pagination [collectionSize]="songs?.length" [page]="page" [pageSize]="pageSize"
                        (onPageChange)="onPageChange($event)"></rap-pagination>
                </div>
            </div>
        </div>

    </ng-container>


</div>

<div style="padding: 20px; text-align: center; margin-top: 100px;" *ngIf="!songs || songs?.length === 0 && !isLoading">
    <img src="../assets/images/question.svg" />
    <div class="no-match">No songs found</div>
</div>