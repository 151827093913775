<div class="card-container">
    <div class="card-main">

        <div>RAP ALMANAC: THE HIPHOP WORD COUNT</div>
        <rap-spinner class="loader-overlay" *ngIf="isLoading"></rap-spinner>
        <div class="card" *ngIf="card!==null">
            <img [src]="card.path !== null ? card.path : 'assets/images/favicon.png'" class="logo">
            <h3>{{ card.searchWord }}</h3>
            <div *ngIf="card.chartType === 'Bar chart'">
                <canvasjs-chart [options]="getChartOptions(card)"
                    [styles]="{ width: '100%', height:'300px'}"></canvasjs-chart>
            </div>
            <div *ngIf="card.chartType === 'Timeline'">
                <canvasjs-chart [options]="getChartOptionsTimeLine(card)"
                    [styles]="{ width: '80%', height:'300px'}"></canvasjs-chart>
            </div>
        </div>

    </div>

</div>