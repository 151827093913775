import { Component } from '@angular/core';

@Component({
  selector: 'rap-albums',
  templateUrl: './albums.component.html',
  styleUrls: ['./albums.component.scss']
})
export class AlbumsComponent {

}
