import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, switchMap, tap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { getUsers, getUsersSuccess, getUserById, getUserByIdSuccess, createNewUser, createNewUserSuccess, updateUser, updateUserSuccess, deleteUser, deleteUserSuccess, usersError, approveUser, approveUserSuccess, performAction, performActionSuccess, getRoles, getRolesSuccess, getRosters, getRostersSuccess } from './users.action';
import { User } from 'src/app/models/users/user.model';
import { UsersService } from '../users.service';
import { DropdownValues } from './users.state';

@Injectable()
export class UsersEffects {
  getUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUsers),
      switchMap((action) =>
        this.usersService.getUsers(action.page).pipe(
          map((data: any) => {
            return getUsersSuccess({ users: data });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(usersError({ errorMessage: error.error.message || 'Something went wrong, please try again later!' }));
          }),
        ),
      ),
    ),
  );

  getUserById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserById),
      switchMap((action) =>
        this.usersService.getUserById(action.userId).pipe(
          map((user: User) => {
            return getUserByIdSuccess({ user });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(usersError({ errorMessage: error.error.message || 'Something went wrong, please try again later!' }));
          }),
        ),
      ),
    ),
  );

  approveUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(approveUser),
      switchMap((action) =>
        this.usersService.approveUser(action.userId).pipe(
          map((user: User) => {
            this.toastr.success('User approved.', '', {
              positionClass: 'toast-top-center',
              closeButton: false,
            });
            return approveUserSuccess({ userId: action.userId});
          }),
          catchError((error: HttpErrorResponse) => {
            return of(usersError({ errorMessage: error.error.message || 'Something went wrong, please try again later!' }));
          }),
        ),
      ),
    ),
  );

  getRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getRoles),
      switchMap(() =>
        this.usersService.getRoles().pipe(
          map((roles: DropdownValues[]) => {
            return getRolesSuccess({ roles });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(usersError({ errorMessage: error.error.message || 'Something went wrong, please try again later!' }));
          }),
        ),
      ),
    ),
  );

  getRosters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getRosters),
      switchMap(() =>
        this.usersService.getRosters().pipe(
          map((rosters: DropdownValues[]) => {
            return getRostersSuccess({ rosters });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(usersError({ errorMessage: error.error.message || 'Something went wrong, please try again later!' }));
          }),
        ),
      ),
    ),
  );

  createNewUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createNewUser),
      switchMap((action) =>
        this.usersService.createNewUser(action.newUser).pipe(
          map((data: any) => {
            return createNewUserSuccess();
          }),
          catchError((error: HttpErrorResponse) => {
            return of(usersError({ errorMessage: error.error.message || 'Something went wrong, please try again later!' }));
          }),
        ),
      ),
    ),
  );

  createNewUserSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createNewUserSuccess),
        tap(() => {
          this.toastr.success('User created successfully', '', {
            positionClass: 'toast-top-center',
            closeButton: false,
          });
          this.router.navigate(['/dashboard/users']);
        }),
      ),
    { dispatch: false },
  );

  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateUser),
      mergeMap((action) =>
        this.usersService.updateUser(action.updatedUser).pipe(
          map(() => {
            return updateUserSuccess();
          }),
          catchError((error) => {
            return of(usersError({ errorMessage: error.error.message || 'Something went wrong, please try again later!' }));
          }),
        ),
      ),
    ),
  );

  updateUserSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateUserSuccess),
        tap(() => {
          this.toastr.success('User updated', '', {
            positionClass: 'toast-top-center',
            closeButton: false,
          });
          this.router.navigate(['/dashboard/users']);
        }),
      ),
    { dispatch: false },
  );

  deleteUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteUser),
      mergeMap((action) =>
        this.usersService.deleteUser(action.userId).pipe(
          map(() => {
            this.toastr.success('User deleted successfullly', '', {
              positionClass: 'toast-top-center',
              closeButton: false,
            });
            return deleteUserSuccess({ userId: action.userId });
          }),
          catchError((error) => {
            return of(usersError({ errorMessage: error.error.message || 'Something went wrong, please try again later!' }));
          }),
        ),
      ),
    ),
  );

  performAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(performAction),
      mergeMap((action) =>
        this.usersService.performAction(action.actionOnUsers).pipe(
          map(() => {
            this.toastr.success(`User successfully ${action.actionOnUsers.action}`, '', {
              positionClass: 'toast-top-center',
              closeButton: false,
            });
            return performActionSuccess({ actionOnUsers: action.actionOnUsers });
          }),
          catchError((error) => {
            return of(usersError({ errorMessage: error.error.message || 'Something went wrong, please try again later!' }));
          }),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private usersService: UsersService,
    private toastr: ToastrService,
    private router: Router,
  ) {}
}
