<div class="producers-section">
    <div class="section-title">
        {{ title }}
    </div>
    <div class="error-container" *ngIf="errorMessage">
        {{ errorMessage }}
    </div>
    <div class="card">
        <div class="card-body">
            <form [formGroup]="producerForm" (ngSubmit)="addUpdateProducer()">
                <div class="form-group">
                    <label for="name">Name</label>
                    <div class="input-container">
                        <input type="text" class="form-control" [class.submitted]="submitted" formControlName="name" />
                    </div>
                    <div *ngIf="submitted && f['name'].errors" class="rap-error-text">
                        <span *ngIf="f['name'].errors?.['required']">Name is required</span>
                    </div>
                </div>
                <div class="producer-action-btns">
                    <button type="submit" class="btn btn-dark save-producer-btn">Save</button>
                    <button type="button" class="btn btn-light reset-producer-btn">
                        Reset
                    </button>
                    <button type="button" class="btn btn-light cancel-producer-btn"
                        routerLink="/dashboard/contributions/producers">
                        Cancel
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>