import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { UsersState } from './users.state';
import { UsersSelectors } from './users.selectors';
import { CreateUserRequest } from 'src/app/models/users/create-user-request.model';
import { UpdateUserRequest } from 'src/app/models/users/update-user-request.model';
import { getUsers, getUserById, createNewUser, updateUser, deleteUser, resetEditableUser, approveUser, performAction, getRoles, getRosters, clearErrorMessage } from './users.action';

@Injectable({
  providedIn: 'root',
})
export class UsersFacade {
  users$ = this.usersSelectors.users$;
  editableUser$ = this.usersSelectors.editableUser$;
  roles$ = this.usersSelectors.roles$;
  rosters$ = this.usersSelectors.rosters$;
  errorMessage$ = this.usersSelectors.errorMessage$;
  usersCount$ = this.usersSelectors.usersCount$;
  constructor(
    private store: Store<UsersState>,
    private usersSelectors: UsersSelectors,
  ) {}

  getUsers(page:number) {
    this.store.dispatch(getUsers({page}));
  }

  getUserById(userId: string) {
    this.store.dispatch(getUserById({userId}));
  }

  getRoles() {
    this.store.dispatch(getRoles());
  }

  getRosters() {
    this.store.dispatch(getRosters());
  }

  // Add the createNewUser method
  createNewUser(newUser: CreateUserRequest) {
    this.store.dispatch(createNewUser({ newUser }));
  }

  // Add the updateUser method
  updateUser(updatedUser: UpdateUserRequest) {
    this.store.dispatch(updateUser({ updatedUser }));
  }

  deleteUser(userId: string) {
    this.store.dispatch(deleteUser({ userId }));
  }

  resetEditableUser() {
    this.store.dispatch(resetEditableUser());
  }

  approveUser(userId: string) {
    this.store.dispatch(approveUser({ userId }));
  }

  performAction(actionOnUsers: any) {
    this.store.dispatch(performAction({ actionOnUsers }));
  }

  clearErrorMessage() {
    this.store.dispatch(clearErrorMessage());
  }
}
