import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environments';
import { CreateStudentRequest } from '../models/students/create-student-request.model';
import { UpdateStudentRequest } from '../models/students/update-student-request.model';

@Injectable({
  providedIn: 'root',
})
export class StudentsService {
  private readonly BASE_URL = 'baseUrl';
  private url: string;

  constructor(private httpClient: HttpClient) {
    this.url = environment.baseUrl;
  }

  getStudents(rosterId: string) {
    return this.httpClient.get<any>(this.url + `v2/users/roster/` + rosterId);
  }

  deleteStudents(rosterId: string, studentId: string) {
    return this.httpClient.delete<any>(
      this.url + `v2/rosters/` + rosterId + `/student/` + studentId,
    );
  }

  createNewStudent(rosterId: string, newStudent: CreateStudentRequest) {
    return this.httpClient.post(
      this.url + `v2/rosters/` + rosterId + `/add`,
      newStudent,
      {
        responseType: 'text',
      },
    );
  }

  updateStudents(
    rosterId: string,
    studentId: string,
    updatedStudent: UpdateStudentRequest,
  ) {
    return this.httpClient.put(
      this.url + `v2/rosters/` + rosterId + `/student/` + studentId,
      updatedStudent,
      {
        responseType: 'text',
      },
    );
  }
}
